import { CancelIcon } from '@/assets/assets.export'
import { Button } from '@/components/Button'
import Lottie from 'react-lottie'
import animationData from '@/assets/upgrade-animation.json'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
}

type Props = {
    closeModal: () => void
    nextStep?: () => void
    prevStep?: () => void
}
const AccountingIntegrationSuccess = ({ closeModal, nextStep, platform }: Props & { platform: string }) => {
    return (
        <section className='min-h-[15rem] w-[25rem] overflow-scroll'>
            <div className='flex justify-end  p-3 px-4'>
                <CancelIcon className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer' onClick={() => closeModal()} />
            </div>
            <Lottie options={defaultOptions} height={150} width={150} />

            <div className='mx-4 mt-5 text-center'>
                <h1 className='font-medium text-lg'>Integration was successful</h1>
                <h3 className='mb-2 text-sm text-gray7 text-center mt-1'>
                    <span className='capitalize'>{platform}</span> has been integrated successfully. Kindly map table
                    titles
                </h3>
            </div>
            <div className='flex justify-center my-5 mt-7'>
                <Button
                    onClick={nextStep}
                    size={'lg'}
                    variant={'outline'}
                    className='border-brand text-brand py-3 rounded-lg'
                >
                    Map fields
                </Button>
            </div>
        </section>
    )
}
export default AccountingIntegrationSuccess
