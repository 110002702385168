import { CancelIcon, Quickbooks } from '@/assets/assets.export'
import { Select, SelectValue, SelectContent, SelectItem, SelectTrigger } from '@/components/Select'
import { Button } from '@/components/Button'
import { Controller, useFormContext } from 'react-hook-form'
import { AccountingSetUpFormData } from './AccountingSetupModal'

type Props = {
    closeModal: () => void
    nextStep?: () => void
    prevStep?: () => void
}
const accountingPlatform = [{ name: 'Quickbooks', icon: Quickbooks }, { name: 'Sage' }]
const accountingConsts = {
    manual: 'manual',
}
const AccountingPlatform = ({ closeModal, nextStep }: Props) => {
    const { control, watch } = useFormContext<AccountingSetUpFormData>()
    const platform = watch('platform')

    return (
        <section className='min-h-[15rem] w-[27rem] overflow-scroll'>
            <div className='flex items-center border-b border-[#DADCE0] p-3 px-4 justify-between'>
                <h2 className='text-center text-lg font-medium opacity-80'>Setup your accounting</h2>
                <CancelIcon className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer' onClick={() => closeModal()} />
            </div>
            <div className='mx-4 mt-5'>
                <h3 className='mb-2 text-sm text-gray7'>Select your preferred accounting platform</h3>

                <Controller
                    name='platform'
                    rules={{
                        required: true,
                    }}
                    control={control}
                    render={({ field }) => {
                        return (
                            <Select onValueChange={field.onChange} value={field.value}>
                                <SelectTrigger className='text-base h-[3rem] border-gray3 flex '>
                                    <SelectValue placeholder={'Quickbooks, sage, xero etc'} />
                                </SelectTrigger>
                                <SelectContent className=''>
                                    {accountingPlatform.map((platform, idx) => (
                                        <SelectItem value={platform.name.toLowerCase()} key={idx}>
                                            <div className='flex items-center gap-2'>
                                                <Quickbooks className='w-4 h-4' />
                                                {platform.name}
                                            </div>
                                        </SelectItem>
                                    ))}

                                    <SelectItem
                                        value={accountingConsts.manual}
                                        className='text-center flex-1 flex justify-center border-t border-gray5 mt-2 pt-2'
                                    >
                                        or manually upload chart of accounts
                                    </SelectItem>
                                </SelectContent>
                            </Select>
                        )
                    }}
                />
            </div>
            <div className='border-t border-gray4 flex mt-6 justify-end items-center pt-2.5 px-4'>
                <Button onClick={nextStep} size={'lg'} disabled={!platform}>
                    Next
                </Button>
            </div>
        </section>
    )
}

export default AccountingPlatform
