import { CancelIcon, FolderIcon } from '@/assets/assets.export'
import ModalWrapper from '../ModalWrapper'
import { useRef, useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { generateFileName, ModuleNameEnum } from '@/hooks/useSingleFileUpload'
import { Button } from '@/components/Button'
import { useGenerateSignedUrl } from '@/services/vault/mutations'
import { signedUrlContext, VaultCategory, VaultListData } from '@/services/vault/types'
import { useAuthContext, useVaultContext } from '@/context'
import { v4 as uuidv4 } from 'uuid'
import { useGetVaultItems } from '@/services/vault/queries'
import { PlusSquare } from 'lucide-react'
import { CreateCategoryModal } from './CreateCategoryModal'
import { categoryProps } from '@/modules/dashboard/Vault/partials/VaultSettings/VaultCategories'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    vault?: VaultListData
    vaultCategories?: VaultCategory[]
}

export const AddNewFileModal = ({ closeModal, vault, vaultCategories }: Props) => {
    const [formState, setFormState] = useState({
        folder: '',
        category: '',
    })
    const { setSignedUrlData } = useVaultContext()
    const { selectedAccount } = useAuthContext()
    const [, /*selectedCategory*/ setSelectedCategory] = useState<categoryProps>()
    const [openSelectContainer, setOpenSelectContainer] = useState(false)
    const [createCategory, setCreateCategory] = useState(false)

    const docRef = useRef<HTMLInputElement>(null)
    const [docFiles, setDocFiles] = useState<File[]>()
    const removeFile = (index: number) => {
        setDocFiles(prevFiles => prevFiles?.filter((_, i) => i !== index))
    }

    const { mutate: generateURLFn, isLoading: isLoadingSignedUrls } = useGenerateSignedUrl({
        onSuccess: data => {
            docFiles?.forEach((file, i) => {
                setSignedUrlData(prev => [
                    ...(prev ?? ([] as signedUrlContext[])),
                    {
                        signedUrl: data?.[i].signedUrl,
                        file: file,
                        id: uuidv4(),
                        path: data[i].path,
                        vault: vault?.id as string,
                        parent: formState.folder,
                        category: formState.category,
                    },
                ])
            })
            closeModal(false)
        },
    })

    const isDisabled = !formState.folder || !formState.category || docFiles?.length === 0

    const handleCreate = () => {
        const filesToUpload = docFiles?.map(file => ({
            fileName: generateFileName({
                module: ModuleNameEnum.VAULT,
                unique_identifier: `vault-${file.name?.split('.')?.[0]}`,
                org_id: selectedAccount?.org?.id ?? '',
                selectedFileName: file?.name as string,
            }),
            contentType: file.type,
        }))

        generateURLFn({
            files: filesToUpload,
            module: 'vault',
        })
    }

    const { data } = useGetVaultItems({
        queryParams: {
            vault: vault?.id as string,
            parent: vault?.id as string,
        },
    })

    return (
        <ModalWrapper variants={undefined} formId='fileForm' closeModal={() => closeModal(false)} shouldConfirmClose>
            <main className=' w-screen lg:w-[31rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                    <span className='flex-1 text-xl font-medium ml-5'>Add new file</span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <form id='fileForm' onSubmit={e => e.preventDefault()}>
                    <div className='px-4 lg:px-7 p-7 pb-1 pt-5 overflow-y-scroll'>
                        <div className='flex flex-col flex-1 '>
                            <label className='mb-3' htmlFor='folder'>
                                Choose a folder to save it in
                            </label>
                            <Select
                                name='folder'
                                onValueChange={value => {
                                    setFormState(prevState => ({
                                        ...prevState,
                                        folder: value,
                                    }))
                                }}
                                value={formState.folder}
                            >
                                <SelectTrigger className=' text-base h-[3rem]  text-[#5E5E5E] border-gray3 flex '>
                                    {formState?.folder ? <SelectValue /> : <span>Select root folder</span>}
                                </SelectTrigger>
                                <SelectContent className='max-h-[12rem] overflow-y-scroll w-[27.5rem]'>
                                    <SelectItem value={vault?.id as string} className='pl-3'>
                                        <div className='flex items-center gap-3'>
                                            <FolderIcon className={`w-4 h-4`} />
                                            <div className='flex flex-col items-start'>
                                                <h6 className='text sm'>Root Vault</h6>
                                            </div>
                                        </div>
                                    </SelectItem>
                                    {data
                                        ?.filter(item => item.type === 'folder')
                                        .map(num => (
                                            <SelectItem value={num?.id} className='pl-3' key={num?.id}>
                                                <div className='flex items-center gap-3'>
                                                    <FolderIcon className={`w-4 h-4`} />
                                                    <div className='flex flex-col items-start'>
                                                        <h6 className='text sm'>{num?.name}</h6>
                                                    </div>
                                                </div>
                                            </SelectItem>
                                        ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div className='flex flex-col gap-3 my-5'>
                            <label className='flex items-center gap-1'>Select file(s)</label>
                            <div
                                className={`flex flex-col gap-2 border border-[#DADCE0] rounded-lg px-2 lg:px-4 p-4 max-h-[180px] overflow-y-scroll`}
                            >
                                <label htmlFor='file_input' className='cursor-pointer'>
                                    {!docFiles?.length ? (
                                        <div className='text-[#838383] text-sm font-semibold text-center'>
                                            Click here to upload one or more files
                                            <br />
                                            <p className='mt-1 text-[10px] font-medium text-gray7'>
                                                DOCX, PDF, XLSX, PNG files only | 2MB max file
                                            </p>
                                        </div>
                                    ) : (
                                        <p className='text-[10px] w-fit px-2 py-1 bg-[#EFEFF9] rounded-lg -mt-2'>
                                            add more files
                                        </p>
                                    )}
                                    <input
                                        type='file'
                                        name='files'
                                        id='file_input'
                                        hidden
                                        multiple
                                        ref={docRef}
                                        onChange={e => setDocFiles(Array.from(e.target.files as FileList))}
                                    />
                                </label>

                                {docFiles && docFiles?.length > 0 && (
                                    <div className='flex flex-col gap-3'>
                                        {docFiles?.map((docFile, index) => (
                                            <div
                                                key={index}
                                                className='bg-white flex justify-between items-center rounded-lg'
                                            >
                                                <div className='flex items-center gap-4'>
                                                    <div className='text-xs font-semibold'>
                                                        <h2 className='text-[#202020] truncate w-[12rem] lg:w-[15.5rem]'>
                                                            {docFile?.name}
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className='flex gap-2 text-[10px]'>
                                                    <p className='text-gray7'>{convertToBytesText(docFile.size)}</p> |{' '}
                                                    <p
                                                        className='text-brand font-semibold cursor-pointer'
                                                        onClick={() => {
                                                            removeFile(index)
                                                            docRef.current?.click()
                                                        }}
                                                    >
                                                        Change
                                                    </p>{' '}
                                                    |{' '}
                                                    <span className='cursor-pointer' onClick={() => removeFile(index)}>
                                                        <CancelIcon className='text-red h-4 w-4 !stroke-[#872D10]' />
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='flex flex-col flex-1 mb-3'>
                            <label className='mb-3' htmlFor='folder'>
                                Select file category
                            </label>
                            <Select
                                name='category'
                                onValueChange={value => {
                                    setFormState(prevState => ({
                                        ...prevState,
                                        category: value,
                                    }))
                                }}
                                value={formState.category}
                                onOpenChange={setOpenSelectContainer}
                                open={openSelectContainer}
                            >
                                <SelectTrigger className=' text-base h-[3rem]  text-[#5E5E5E] border-gray3 flex '>
                                    {formState?.category ? <SelectValue /> : <span>Select file category</span>}
                                </SelectTrigger>
                                <SelectContent className='max-h-[12rem] overflow-y-scroll w-[27.5rem]'>
                                    <button
                                        className='flex items-center gap-1 pl-2 py-1.5 hover:bg-[#EFEFF9] w-full text-sm'
                                        onClick={() => {
                                            setCreateCategory(true)
                                            setOpenSelectContainer(false)
                                        }}
                                    >
                                        <PlusSquare size={15} /> Create new category
                                    </button>
                                    {vaultCategories?.length === 0 ? (
                                        <p className='px-2 text-sm py-1'>No category created yet</p>
                                    ) : (
                                        vaultCategories?.map(item => (
                                            <SelectItem value={item?.id} className='pl-3' key={item?.id}>
                                                {item.name}
                                            </SelectItem>
                                        ))
                                    )}
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                </form>
                <div className='flex items-center border-t border-[#DADCE0] p-4 justify-between'>
                    <>&nbsp;</>
                    <Button
                        onClick={handleCreate}
                        loading={isLoadingSignedUrls}
                        disabled={isDisabled || isLoadingSignedUrls}
                    >
                        Upload file(s)
                    </Button>
                </div>
                {createCategory && (
                    <CreateCategoryModal closeModal={setCreateCategory} setSelectedCategory={setSelectedCategory} />
                )}
            </main>
        </ModalWrapper>
    )
}

// const categories = ['Contracts', 'Receipts', 'SLAs', 'Invoices', 'Bills', 'Others']
export const convertToBytes = (sizeString: string): number => {
    const [size, unit] = sizeString.split(' ')

    const sizeInMB = parseFloat(size)

    if (unit === 'MB') {
        return sizeInMB * 1024 * 1024
    }
    if (unit === 'KB') {
        return sizeInMB * 1024
    }
    return 1
}

export const convertToBytesText = (bytes: number) => {
    if (bytes < 1024 ** 2) {
        return `${(bytes / 1024).toFixed(2)} KB`
    } else if (bytes < 1024 ** 3) {
        return `${(bytes / 1024 ** 2).toFixed(2)} MB`
    } else {
        return `${(bytes / 1024 ** 3).toFixed(2)} GB`
    }
}
