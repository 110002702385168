import ModalWrapper from '../ModalWrapper'
import { BlueWarning } from '@/assets/assets.export'

type Props = {
    closeModal: () => void
}

export const UserNoLeaveApprovalWarning = ({ closeModal }: Props) => {
    return (
        <ModalWrapper shouldConfirmClose closeModal={() => closeModal()}>
            <div className='w-screen lg:w-[25rem] p-6 flex items-center justify-center flex-col'>
                <div className='p-3.5 rounded-full bg-[#EBF5FF]'>
                    <BlueWarning />
                </div>
                <p className='mt-8 text-center font-medium'>
                    No approval policy yet. <br />
                    Kindly reach out to the owner of the organization.
                </p>
                <div className='mt-16 flex gap-4 items-center justify-center'>
                    {/* <Button
                        onClick={() => {
                            navigate(navLink)
                        }}
                        className='rounded-lg bg-black hover:bg-black'
                    >
                        Set it up now
                    </Button> */}
                </div>
            </div>
        </ModalWrapper>
    )
}
