import { createMutation } from '../api/mutation'
import { folderItemsKey, orgVaultsCategoryKey, orgVaultsKey, vaultItemsKey, vaultStorageKey } from './queries'
import { CreateVaultType, signedUrlData } from './types'

export const useCreateVault = createMutation<CreateVaultType>({
    url: '/vault',
    keysToRefetch: [orgVaultsKey],
    method: 'POST',
})

export const useUpdateVault = createMutation({
    url: '/vault',
    keysToRefetch: [orgVaultsKey, ['/single-vault', 'id']],
    method: 'PATCH',
})

export const useDeleteVault = createMutation({
    url: '/vault',
    keysToRefetch: [orgVaultsKey],
    method: 'DELETE',
})

export const useCreateVaultCategory = createMutation({
    url: '/vault/category',
    keysToRefetch: [orgVaultsCategoryKey],
    method: 'POST',
})
export const useUpdateVaultCategory = createMutation({
    url: '/vault/category',
    keysToRefetch: [orgVaultsCategoryKey, ['single-catgeory', 'id']],
    method: 'PATCH',
})
export const useDeleteVaultCategory = createMutation({
    url: '/vault/category',
    keysToRefetch: [orgVaultsCategoryKey],
    method: 'DELETE',
})
export const useCreateVaultItem = createMutation({
    url: '/vault/item',
    keysToRefetch: [orgVaultsKey, vaultItemsKey, folderItemsKey, vaultStorageKey],
    method: 'POST',
})

export const useUpdateVaultItem = createMutation({
    url: '/vault/item',
    keysToRefetch: [vaultItemsKey, ['single-item', 'id'], folderItemsKey],
    method: 'PATCH',
})
export const useDeleteVaultItem = createMutation({
    url: '/vault/item',
    keysToRefetch: [vaultItemsKey, folderItemsKey, vaultStorageKey],
    method: 'DELETE',
})

export const useGenerateSignedUrl = createMutation<signedUrlData[]>({
    url: '/s3/signed-url',
    method: 'POST',
})

export const useMoveVaultItem = createMutation({
    url: '/vault/item/move',
    method: 'POST',
    keysToRefetch: [orgVaultsKey, vaultItemsKey, folderItemsKey, vaultStorageKey],
})

export const useSetVaultPassword = createMutation({
    url: '/vault/set-password',
    method: 'PATCH',
    keysToRefetch: [orgVaultsKey, ['/single-vault']],
})

export const useUpdateVaultPassword = createMutation({
    url: '/vault/update-password',
    method: 'PATCH',
})

export const useVerifyVaultPassword = createMutation({
    url: '/vault/verify-password',
    method: 'POST',
})

export const useMoveToTrash = createMutation({
    url: '/vault/item/trash',
    method: 'DELETE',
    keysToRefetch: [vaultItemsKey, folderItemsKey, vaultStorageKey],
})

export const useRestoreFromTrash = createMutation({
    url: '/vault/item/restore',
    method: 'POST',
    keysToRefetch: [vaultItemsKey, folderItemsKey, vaultStorageKey],
})

export const usePurchaseVaultStorage = createMutation({
    url: '/vault/purchase-storage',
    method: 'POST',
    keysToRefetch: [vaultItemsKey, folderItemsKey, vaultStorageKey],
})

export const useGetZippedFolder = createMutation({
    url: '/vault/item/download-zip',
    method: 'POST',
})
