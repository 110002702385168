import { ReactComponent as AccountsIcon } from '@/assets/accounts-icon.svg'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/Accordion'
import { ChevronDown, PlusSquare } from 'lucide-react'
import { cn } from '@/lib/styling'
import { BsBasket } from 'react-icons/bs'

const data = [
    {
        name: 'Reimbursement account',
        mappedNameDetails: ['Travel expenses', 'Office supplies', 'Miscellaneous'],
    },
    {
        name: 'Paying for invoices',
        mappedNameDetails: ['Travel expenses', 'Miscellaneous'],
    },
]

const AccountMapping = () => {
    return (
        <section className='mb-5 bg-white rounded-xl p-7 flex-1'>
            <div className='border-gray/40 border-b pb-5'>
                <h2 className='font-medium'>Account mapping</h2>
                <p className='text-sm text-gray7'>
                    Map your Bizpend accounts to your <span className='text-brand font-medium'>uploaded accounts</span>{' '}
                    for seamless tracking and categorization
                </p>
            </div>

            <Accordion type='single' collapsible className='mt-8 space-y-6'>
                {data.map(accounting => (
                    <AccountMappingCard key={accounting.name} {...accounting} />
                ))}
            </Accordion>

            <section className='mt-16'>
                <div className='pb-5'>
                    <h2 className='font-medium flex gap-3 '>
                        Unmatched Uploaded accounts
                        <span className='bg-gray7 text-white px-4 p-0.5 font-medium rounded-2xl text-sm'>2</span>
                    </h2>
                    <p className='text-sm text-gray7'>
                        Uploaded accounts that have not been matched to any Bizpend account
                    </p>
                </div>
                <div className='flex flex-col gap-3'>
                    {['Project equipment', 'Miscellaneous income'].map(data => (
                        <span className='w-fit text-gray7 bg-gray/10 rounded-md px-2 p-1.5  font-medium text-sm'>
                            {data}
                        </span>
                    ))}
                </div>
            </section>
        </section>
    )
}

export default AccountMapping

type Props = {
    name: string
    mappedNameDetails: string[]
}

const AccountMappingCard = ({ mappedNameDetails, name }: Props) => {
    return (
        <AccordionItem value={name} className='border-gray/30 border rounded-lg p-2'>
            <AccordionTrigger
                className={cn('font-normal  py-2 px-2 ')}
                righticon={<ChevronDown className='w-4 h-4 chevron' />}
            >
                <span className='flex justify-between flex-1 mr-2'>
                    <span className='flex items-center gap-3 text-base text-gray7 font-medium'>
                        <AccountsIcon className='w-6 h-6' />

                        {name}
                    </span>
                    <span className='bg-brand/10 text-brand px-4 p-0.5 font-medium rounded-lg text-sm'>
                        {mappedNameDetails?.length}
                    </span>
                </span>
            </AccordionTrigger>
            <AccordionContent className='bg-transparent mx-1.5'>
                <div className='flex gap-3 mt-3 border-y border-gray/30 py-3'>
                    {mappedNameDetails.map(mappedName => (
                        <span className='flex items-center gap-3.5 border-gray/30 border bg-gray/5 rounded-md px-2 p-1.5  font-medium text-sm'>
                            <span className='text-gray7'>{mappedName}</span>
                            <button aria-label='Icon'>
                                <BsBasket className='text-errorRed' strokeWidth={0.3} />
                            </button>
                        </span>
                    ))}
                </div>
                <div className='mt-3 mb-1.5'>
                    <button
                        aria-label='map more accounts'
                        className='flex items-center gap-2 text-brand text-sm font-medium'
                    >
                        <PlusSquare className='w-4 h-4' />
                        Map more accounts
                    </button>
                </div>
            </AccordionContent>
        </AccordionItem>
    )
}
