import { EmployeeType } from '@/types/org'
import { formatAmountNoSymbol } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import filter from '@/assets/filter.svg'
import down from '@/assets/down-arrow.svg'

type BudgetUsersProps = {
    users: EmployeeType[]
}
export const BudgetUsers = ({ users }: BudgetUsersProps) => {
    const [searchQuery, setSearchQuery] = useState('')

    return (
        <div className='mt-4'>
            <div className='flex items-center gap-5 justify-between'>
                <div className='border border-[#DADCE0] h-[44px] w-[200px] lg:w-[314px] rounded-lg flex items-center overflow-hidden '>
                    <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full bg-white'>
                        <CiSearch fontSize={20} stroke='2' color='#A8A8A8' />
                    </div>
                    <input
                        type='text'
                        placeholder='Search by user name'
                        className='h-full w-[90%] outline-none text-sm'
                        onChange={e => setSearchQuery(e.target.value)}
                        // onKeyDown={handleKeyDown}
                    />
                </div>
                <div className='flex items-center gap-5'>
                    <div className='h-[35px] w-[35px] border-2 border-[#DADCE0] rounded-lg flex items-center justify-center p-1.5 cursor-pointer'>
                        <img src={filter} alt='button' />
                    </div>
                    <div className='h-[35px] w-[35px] border-2 border-[#DADCE0] rounded-lg flex items-center justify-center p-1.5 cursor-pointer'>
                        <img src={down} alt='button' />
                    </div>
                </div>
            </div>
            <div className='mt-6 grid grid-cols-3 gap-4'>
                {users
                    .filter(item =>
                        `${item?.user?.first_name} ${item?.user?.last_name}`
                            ?.toLowerCase()
                            .includes(searchQuery.toLowerCase())
                    )
                    .map(user => (
                        <div className='p-5 rounded-2xl border border-gray8'>
                            <div className='flex mb-3 gap-2 items-center'>
                                {user?.user?.profile_photo ? (
                                    <img
                                        src={user?.user?.profile_photo}
                                        alt=''
                                        className='w-[30px] h-[30px] rounded-full hidden lg:block'
                                    />
                                ) : (
                                    <p className='w-[30px] h-[30px] bg-[#D9D9D9] text-[#838383] hidden lg:flex justify-center items-center rounded-full tracking-wider'>
                                        {capitalizeText(user?.user?.first_name as string)?.substring(0, 1)}
                                        {capitalizeText(user.user?.last_name as string)?.substring(0, 1)}
                                    </p>
                                )}
                                <p className='text-lg font-semibold'>
                                    {user?.user?.first_name} {user?.user?.last_name}
                                </p>
                            </div>
                            <div className=''>
                                <p className='text-gray7 text-sm mb-1'>Spent</p>
                                <p className='text-lg font-semibold'>{formatAmountNoSymbol(0)}</p>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
}
