import { BillType } from '@/services/bill-payment/types'
import { formatAmount } from '@/utils/money'
import { differenceInCalendarDays, format } from 'date-fns'
import { Menu } from '@headlessui/react'
import BillStatusBadge from './BillStatusBadge'
import { BillStatus } from '@/types/bill-payments'
import React, { useEffect, useState } from 'react'
import { Button } from '@/components'
import { useMutationApproveBill } from '@/services/bill-payment/mutations'
import { useGetScheduledBill } from '@/services/bill-payment/queries'
import { Link } from 'react-router-dom'
import { PDFIcon } from '@/assets/assets.export'
import downArrow from '@/assets/whiteDownload.svg'
import { handleShowImage } from '../../Expenses/utils/utils'
import { FILE_TYPE } from '../../Expenses/utils/enums'
import { TfiAngleDown } from 'react-icons/tfi'
import DeleteBillPrompt from '@/components/Modals/BillPayments/DeleteBIllPrompt'
import { SettleBillModal } from '@/components/Modals/BillPayments/SettleBillModal'
import { CreateBill } from '@/components/Modals/BillPayments/CreateBill'
import { ApproversWrapper } from './ApproversWrapper'
import WithAuthorizeBillPermission from './WithAuthorizeBillPermission'
import DeclineBillModal from '@/components/Modals/BillPayments/DeclineBillModal'

enum receipt_type {
    pdf = 'pdf',
}

export const BillDetails = ({
    billDetails,
    setAddVendor,
}: {
    billDetails: BillType
    setAddVendor: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const bill_details_list = [
        {
            title: 'Bill ID',
            desc: billDetails?.bill_id,
        },
        {
            title: 'Vendor name',
            desc: billDetails?.vendor?.name,
        },
        {
            title: 'Category',
            desc: billDetails?.category?.label,
        },
        {
            title: 'Amount',
            desc: formatAmount(billDetails?.amount as number),
        },
        {
            title: 'Created  by',
            desc: billDetails?.initiator?.user?.first_name + ' ' + billDetails?.initiator?.user?.last_name,
        },
        {
            title: 'Approver(s)',
            desc: <ApproversWrapper approvers={billDetails.approvers} />,
        },
        {
            title: 'Issue date',
            desc: billDetails?.createdAt ? format(new Date(billDetails?.createdAt as string), 'dd/MM/yyyy') : 'N/A',
        },
        {
            title: 'Due date',
            desc: billDetails?.due_date
                ? format(new Date(billDetails?.due_date as unknown as string), 'dd/MM/yyyy')
                : 'N/A',
        },
        {
            title: 'Days to due date',
            desc:
                billDetails?.due_date && billDetails?.createdAt
                    ? differenceInCalendarDays(
                          new Date(billDetails?.due_date as string),
                          new Date(billDetails?.createdAt as string)
                      )
                    : 'N/A',
        },
        {
            title: 'Bill type',
            desc: 'Invoice bill',
        },
        {
            title: 'Invoice',
            desc: billDetails?.invoice_url ? 'Attached' : 'Not Attached',
        },
        {
            title: 'Status',
            desc: <BillStatusBadge allowBg={true} status={billDetails?.status as BillStatus} />,
        },
    ]

    const [deleteBillModal, setDeleteBillModal] = useState(false)
    const [declineBillModal, setDeclineBillModal] = useState(false)
    const [isPdf, setIsPdf] = useState(false)
    const [settleBillModal, setSettleBillModal] = useState(false)
    const [schedulePayment, setSchedulePayment] = useState(false)
    const [saveBillModal, setSaveBillModal] = useState(false)

    const { mutate: approveBillFn, isLoading: isApprovingBill } = useMutationApproveBill({})

    const checkifReceiptIsPdf = () => {
        if (billDetails?.invoice_url) {
            const receiptType = billDetails?.invoice_url.split('.').slice(-1)[0]
            if (receiptType === receipt_type.pdf) {
                setIsPdf(true)
                return
            }
        }
        setIsPdf(false)
    }

    const { data: billSchedule } = useGetScheduledBill({
        queryParams: {
            id: (billDetails?._id ?? billDetails?.id) as string,
        },
        enabled: billDetails?.status === 'SCHEDULED',
    })
    console.table(billSchedule)

    useEffect(() => {
        checkifReceiptIsPdf()
    }, [billDetails])

    return (
        <>
            <div className='flex flex-col gap-4 text-[#202020]'>
                <div className='flex items-center justify-between border-b border-[#DADCE0] py-3'>
                    <h1 className='text-lg font-semibold '>
                        {billDetails?.bill_id} - {billDetails?.category?.label ?? 'N/A'} -{' '}
                        {formatAmount(billDetails?.amount as number)}
                    </h1>
                    <Menu as='div' className='relative'>
                        <Menu.Button className=''>
                            <p className='text-[#6E72FF] font-medium'>More options</p>
                        </Menu.Button>
                        <Menu.Items className='w-[260px] flex flex-col absolute right-0 py-[13px] px-0 rounded-lg justify-center border border-[#DADCE0] bg-white mt-2 z-10'>
                            <Menu.Item>
                                <span
                                    className='px-4 py-1 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2'
                                    onClick={() => {
                                        setDeleteBillModal(true)
                                    }}
                                >
                                    Delete bill
                                </span>
                            </Menu.Item>
                        </Menu.Items>
                    </Menu>
                </div>
                <div className=' mt-3 w-full lg:w-[70%]'>
                    <h2 className='text-base font-semibold mb-2'>Bill description</h2>
                    <p className='text-#5E5E5E]'>{billDetails?.description ? billDetails?.description : 'N/A'}</p>
                </div>
                <div className='flex flex-wrap justify-between'>
                    <div className='w-full lg:w-[70%]'>
                        <div className='grid grid-cols-2 md:grid-cols-4 gap-4 gap-y-8 text-base mb-12'>
                            {bill_details_list.map(detail => (
                                <div className=''>
                                    <h2 className='font-semibold mb-2'>{detail.title}</h2>
                                    <p>{detail.desc ?? 'N/A'}</p>
                                </div>
                            ))}
                        </div>
                        <div className='flex justify-between items-center flex-col lg:flex-row'>
                            {billDetails?.status === 'PENDING' ? (
                                <Button
                                    className='bg-transparent hover:bg-transparent w-full lg:w-auto lg:min-w-[120px] border border-gray8'
                                    variant={'outline'}
                                    disabled={isApprovingBill}
                                    onClick={() => {
                                        setSaveBillModal(true)
                                    }}
                                >
                                    Update bill
                                </Button>
                            ) : (
                                <>&nbsp;</>
                            )}

                            <div className='flex justify-end flex-col lg:flex-row w-full lg:w-auto'>
                                <div className='flex flex-wrap lg:flex-nowrap gap-6 justify-normal w-full lg:w-auto mt-6 lg:mt-0 lg:justify-center'>
                                    <WithAuthorizeBillPermission
                                        bill={billDetails}
                                        component={
                                            <React.Fragment>
                                                {billDetails?.status === 'PENDING' && (
                                                    <Button
                                                        className='bg-transparent text-errorRed hover:bg-transparent w-full lg:min-w-[120px] border border-gray8'
                                                        disabled={isApprovingBill}
                                                        onClick={() => {
                                                            setDeclineBillModal(true)
                                                        }}
                                                    >
                                                        Decline
                                                    </Button>
                                                )}
                                                {billDetails?.status === 'DRAFT' && (
                                                    <Button
                                                        className='hover:opacity-90 text-white px-0 w-full lg:min-w-[120px]'
                                                        onClick={() => {
                                                            setSaveBillModal(true)
                                                        }}
                                                    >
                                                        Save bill
                                                    </Button>
                                                )}
                                                {billDetails?.status === 'PENDING' && (
                                                    <Button
                                                        className='hover:opacity-90 text-white px-0 w-full lg:min-w-[120px]'
                                                        onClick={() =>
                                                            approveBillFn({
                                                                id: (billDetails._id ?? billDetails.id) as string,
                                                            })
                                                        }
                                                        loading={isApprovingBill}
                                                        disabled={isApprovingBill}
                                                    >
                                                        Approve
                                                    </Button>
                                                )}
                                                {billDetails?.status === 'APPROVED' && (
                                                    <Menu as='div' className='relative w-full'>
                                                        <Menu.Button
                                                            className={`bg-[#19C37D] text-white px-6 h-[56px] rounded-lg text-[14px] flex justify-center items-center gap-2 disabled:opacity-60 w-full lg:w-[172.56px]`}
                                                        >
                                                            <span>Settle bill</span>

                                                            <TfiAngleDown />
                                                        </Menu.Button>
                                                        <Menu.Items className='w-full lg:w-[172.56px] flex flex-col py-[13px] px-0 rounded-lg justify-center border border-[#DADCE0]  mt-2 z-10 bg-[#EFFFED] text-[#fff] absolute left-0 top-12'>
                                                            <Menu.Item>
                                                                <span
                                                                    className='px-4 py-2 cursor-pointer hover:underline text-[#202020]'
                                                                    onClick={() => {
                                                                        setSchedulePayment(false)
                                                                        setSettleBillModal(true)
                                                                    }}
                                                                >
                                                                    Pay now
                                                                </span>
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                <span
                                                                    onClick={() => {
                                                                        setSchedulePayment(true)
                                                                        setSettleBillModal(true)
                                                                    }}
                                                                    className='px-4 py-2 cursor-pointer hover:underline text-[#202020]'
                                                                >
                                                                    Schedule payment
                                                                </span>
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </Menu>
                                                )}
                                            </React.Fragment>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`overflow-hidden cursor-pointer lg:w-[15rem] h-max  mx-auto mt-5 lg:mt-0 ${billDetails?.invoice_url ? 'bg-[#F9F9F9]' : ''} rounded-lg`}
                        onClick={() => {
                            handleShowImage(billDetails?.invoice_url as string, isPdf ? FILE_TYPE.pdf : FILE_TYPE.image)
                        }}
                    >
                        {billDetails?.invoice_url ? (
                            isPdf ? (
                                <div className='group relative bg-[#F9F9F9] w-full flex flex-col justify-center items-center p-4'>
                                    <PDFIcon className='w-[50px] h-[65px] rounded-lg ' />
                                    <button className='text-sm py-2 text-center inline-block absolute transition-all -bottom-full group-hover:bottom-0  bg-green text-white w-full'>
                                        download receipt
                                    </button>
                                </div>
                            ) : (
                                <div className='group relative w-full overflow-hidden rounded-lg'>
                                    <img
                                        alt='receipt'
                                        src={billDetails.invoice_url}
                                        className='w-full object-cover h-[284px] rounded-lg border-[3px] border-[#DADCE0]'
                                    />
                                    <Link
                                        className='absolute top-3 right-3 bg-[#5E5E5ED4] rounded-sm p-1 cursor-pointer text-[#19C37D]/90'
                                        to={billDetails.invoice_url}
                                        target='_blank'
                                        download
                                    >
                                        <img src={downArrow} alt='button' />
                                    </Link>
                                    <button className='text-sm py-2 text-center inline-block absolute transition-all group-hover:bottom-0 -bottom-full bg-green text-white w-full'>
                                        view receipt
                                    </button>
                                </div>
                            )
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
            {deleteBillModal && (
                <DeleteBillPrompt
                    closeModal={setDeleteBillModal}
                    billId={(billDetails?._id ?? billDetails?.id) as string}
                />
            )}
            {settleBillModal && (
                <SettleBillModal
                    closeModal={setSettleBillModal}
                    bill={billDetails as BillType}
                    schedule={schedulePayment}
                />
            )}
            {saveBillModal && (
                <CreateBill setAddVendor={setAddVendor} closeModal={setSaveBillModal} bill={billDetails as BillType} />
            )}
            {declineBillModal && (
                <DeclineBillModal id={billDetails?._id ?? billDetails?.id} closeModal={setDeclineBillModal} />
            )}
        </>
    )
}
