import { CancelIcon, MoveRight } from '@/assets/assets.export'
import ModalWrapper from '../ModalWrapper'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
// import { useAccountingContext } from '@/context'
import { Button } from '@/components/Button'

type Props = {
    closeModal: () => void
}
export default function AccountingMapToBizpend({ closeModal }: Props) {
    // const { orgGlCode } = useAccountingContext()

    return (
        <ModalWrapper>
            <section className='min-h-[15rem] w-[38rem] overflow-scroll relative'>
                <div className='flex items-center border-b border-[#DADCE0] p-3 px-4 justify-between'>
                    <h2 className='text-center text-lg font-medium opacity-80'>Map your fields to Bizpend fields</h2>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-1 w-5 h-5  cursor-pointer'
                        onClick={() => closeModal()}
                    />
                </div>
                <div className='m-6 '>
                    <ul className='grid grid-cols-4 mb-3'>
                        <li className='font-medium text-base text-left whitespace-nowrap'>Bizpend categories </li>
                        <li className='font-medium text-base'></li>
                        <li className='font-medium text-base text-left min-w-[15rem] col-span-2'>
                            Quickbook categories
                        </li>
                    </ul>
                    <ul className='grid grid-cols-4'>
                        <li>
                            <span className='inline-block bg-gray7/10 text-gray7 rounded p-1.5 px-2  text-xs font-medium '>
                                Travel cost{' '}
                            </span>
                        </li>

                        <li>
                            <MoveRight strokeWidth={1.5} color='#838383' className='mx-auto' />
                        </li>
                        <li className='col-span-2'>
                            <Select name='category'>
                                <SelectTrigger className='font-medium text-xs h-[2.5rem] border border-gray2/50 text-[#5E5E5E]'>
                                    <SelectValue className='' placeholder={'Select category'} />
                                </SelectTrigger>
                                <SelectContent className='w-[10rem]'>
                                    <SelectItem value='128'>128 - Marketing</SelectItem>
                                    <SelectItem value='194'>194 - Electricity</SelectItem>
                                    <SelectItem value='204'>204 - Office supplies</SelectItem>
                                </SelectContent>
                            </Select>
                        </li>
                    </ul>
                </div>
                <div className='border-t border-gray4/50 bg-white py-2.5 flex  justify-end items-center px-4 absolute bottom-0 rounded-b-lg w-full'>
                    <Button size={'lg'} className='font-medium text-xs max-w-[80px] py-2 rounded-lg'>
                        Confirm mapping
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}
