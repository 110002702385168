import { CancelIcon } from '@/assets/assets.export'
import ModalWrapper from '../ModalWrapper'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { useAccountingContext } from '@/context'
import { Button } from '@/components/Button'

type Props = {
    closeModal: () => void
    selectedGlCodeId?: string
}
export default function AddMappingRuleModal({ closeModal }: Props) {
    const { orgGlCode } = useAccountingContext()

    return (
        <ModalWrapper>
            <section className='min-h-[15rem] w-[25rem] overflow-scroll relative'>
                <div className='flex items-center border-b border-[#DADCE0] p-3 px-4 justify-between'>
                    <h2 className='text-center text-base font-medium opacity-80'>Add new mapping rule</h2>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-1 w-5 h-5  cursor-pointer'
                        onClick={() => closeModal()}
                    />
                </div>
                <div className='px-5 mb-20'>
                    <fieldset className='mt-5 flex flex-col  flex-1 '>
                        <label className='mb-1.5  text-sm opacity-80'>If a transaction includes:</label>
                        <div className='flex items-center justify-between border border-gray4/60 rounded-lg p-3'>
                            <div className='text-sm  flex flex-col gap-1'>
                                <h3>Food & Beverages</h3>
                                <p className='flex items-center gap-2'>
                                    <span className='text-xs text-gray7 font-medium'>Category</span>
                                    <span className='w-1 h-1 rounded-full bg-gray7/70 inline-block'></span>
                                    <span className='text-gray7/80 text-xs'>12 unmapped transactions</span>
                                </p>
                            </div>
                            <input aria-label='RAdio' type='radio' className='accent-brand' checked />
                        </div>
                    </fieldset>
                    <fieldset className='mt-5 flex flex-col  flex-1 '>
                        <label className='mb-1.5  text-sm opacity-80' htmlFor='gl_code'>
                            Then set GL codes category to
                        </label>
                        <Select name='gl_code'>
                            <SelectTrigger className='font-medium text-xs h-[3rem] text-[#5E5E5E]  border-gray4/60 '>
                                <SelectValue className='' placeholder={'Select category'} />
                            </SelectTrigger>
                            <SelectContent className=''>
                                {orgGlCode?.codes?.map(code => (
                                    <SelectItem className='text-xs' value={code?.id} key={code?.id}>
                                        {code?.code}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </fieldset>
                </div>
                <div className='border-t border-gray4/50 bg-white py-2.5 flex  justify-end items-center px-4 absolute bottom-0 rounded-b-lg w-full'>
                    <Button size={'lg'} className='font-medium text-xs max-w-[80px]'>
                        Add new rule
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}
