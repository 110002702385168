import { useVaultContext } from '@/context'

import { EmployeeType } from '@/types/org'
import { format } from 'date-fns'
import { capitalizeText } from '@/utils/string'
import { X } from 'lucide-react'
import { VaultCategory } from '@/services/vault/types'
import { advancedFilteringConsts } from '@/context/vault'

type Props = { categories: VaultCategory[]; orgMembers: EmployeeType[] }

const key_types = {
    date_created: 'date_created',
    amount: 'amount',
    category: 'category',
    approved_by: 'approved_by',
    reimbursed_by: 'reimbursed_by',
    created_by: 'created_by',
    shared_with: 'shared_with',
}

export default function FilterVaultChip({ categories, orgMembers }: Props) {
    const { filterVault, setFilterVault } = useVaultContext()

    const { date_created } = filterVault

    const getFilteredDetails = () => {
        const data = Object.keys(filterVault).map(key => {
            const value = filterVault[key as keyof typeof filterVault]
            if (key === key_types.date_created) {
                return {
                    [key]:
                        typeof date_created === 'string'
                            ? `${format(new Date(date_created as unknown as Date), 'do MMM')}`
                            : '',
                }
            }
            if (key === key_types.category) {
                return { [key]: categories.find(category => category.id === value)?.name }
            }
            if (key === key_types.shared_with) {
                const found_user = orgMembers.find(members => members?.id === value)?.user
                const key_title = key.split('_')
                return {
                    [key]:
                        found_user?.first_name &&
                        `${capitalizeText(key_title[0])} ${key_title[1]}: ${capitalizeText(
                            found_user?.first_name as string
                        )} ${capitalizeText(found_user?.last_name as string)}`,
                }
            }
            return { [key]: value as string }
        })
        return data
    }

    const handleDeleteChip = (key: string) => {
        setFilterVault(filter => ({
            ...filter,
            [key]: key === 'date_created' ? advancedFilteringConsts.date_created : '',
            filter_now: !filter.filter_now,
        }))
    }

    return (
        <section className='flex gap-5 flex-wrap mb-2'>
            {getFilteredDetails().map(details => (
                <>
                    {Object.keys(details).map(filter => {
                        const value = details[filter as keyof typeof details]
                        return (
                            value?.length !== 0 &&
                            value !== undefined &&
                            typeof value !== 'boolean' && (
                                <button
                                    type='button'
                                    className=' flex gap-2 px-3 bg-[#D5D5F5] border border-brand text-[#454ADE] min-h-[20px] items-center justify-between py-2 rounded-md text-xs'
                                >
                                    <span className='font-medium  border-r border-brand pr-2'>
                                        <>{value === 'denied' ? 'Declined' : value}</>
                                    </span>
                                    <X
                                        className='w-3 md:w-4 h-3 md:h-4 cursor-pointer'
                                        onClick={() => handleDeleteChip(filter)}
                                    />
                                </button>
                            )
                        )
                    })}
                </>
            ))}
        </section>
    )
}
