import { CancelIcon } from '@/assets/assets.export'
import { Button } from '@/components/Button'
import { useMemo } from 'react'
import { X } from 'lucide-react'
import { CgSpinner } from 'react-icons/cg'
import { useAccountingContext } from '@/context'
import { useGetGLCode } from '@/services/accounting/queries'
import { OrgGlCodeType } from '@/types/gl-code'

type Props = {
    closeModal: () => void
    nextStep?: () => void
    prevStep?: () => void
}
const ManageUploadedGLCodes = ({ closeModal, nextStep, prevStep }: Props) => {
    const { setHasGlCode, setOrgGlCode } = useAccountingContext()
    const { data, isLoading } = useGetGLCode({
        onSuccess: data => {
            if (data?.codes?.length > 0) {
                setHasGlCode(true)
                setOrgGlCode(data)
            }
        },
    })
    const orgGlCode = useMemo(() => data ?? ({} as OrgGlCodeType), [data])

    if (isLoading)
        return (
            <section className='min-h-[15rem] w-[27rem] grid place-items-center'>
                <CgSpinner color='#454ADE' size={100} className='animate-spin opacity-90' />
            </section>
        )
    return (
        <section className='min-h-[15rem] w-[27rem] overflow-scroll relative'>
            <div className='flex items-center border-b border-[#DADCE0] p-3 px-4 justify-between'>
                <h2 className='text-center text-lg font-medium opacity-80'>Preview uploaded GL codes</h2>
                <CancelIcon className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer' onClick={() => closeModal()} />
            </div>
            <div className='mx-4 mt-3 mb-20'>
                <span className='inline-block bg-skyBlue/10 text-skyBlue rounded-full p-1.5  text-xs '>
                    {orgGlCode?.codes?.length} GL codes imported successfully
                </span>

                <div className='flex gap-3 mt-3 flex-wrap'>
                    {orgGlCode?.codes?.map(data => (
                        <span
                            key={data?.id}
                            className=' bg-gray7/10 text-gray7 rounded p-1.5 px-2  text-xs flex items-center  gap-1 '
                        >
                            {data?.code}
                            <button aria-label='Icon'>
                                <X size={15} className='text-red-500' />
                            </button>
                        </span>
                    ))}
                </div>
            </div>
            <div className='bg-white border-t border-gray4 flex justify-end items-center py-2 px-4 gap-4 absolute bottom-0 w-full rounded-b-lg'>
                <Button onClick={prevStep} variant={'outline'} size={'lg'} className='text-xs min-w-[100px] '>
                    back
                </Button>
                <Button onClick={nextStep} size={'lg'} className='text-xs'>
                    Start accounting
                </Button>
            </div>
        </section>
    )
}
export default ManageUploadedGLCodes
