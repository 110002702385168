import { Input } from '@/components'
import ApprovalPolicyListCard from '@/components/ApprovalPolicyBox/ApprovalPolicyListCard'
import ProgressBar from '@/components/ProgressBar'
import { useAuthContext } from '@/context'
import { useGetExpenseApprovalPolicies } from '@/services/settings/queries'
import { ApprovalPolicy, SupportedPolicyConsts } from '@/types/approval-policy'
import { ChevronRight } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { CiSearch } from 'react-icons/ci'
import { useNavigate, useSearchParams } from 'react-router-dom'
import notFound from '@/assets/not-found.svg'
import { CgAddR } from 'react-icons/cg'
import EmptyStates from '@/components/EmptyStates'
import CreateNewPolicyPrompt from '@/components/Modals/Settings/CreateNewPolicyPrompt'
import CreatePolicyModal from '@/components/Modals/ApprovalPolicy/CreatePolicyModal/CreatePolicyModal'
import useAccessToModule from '@/hooks/useAccessToModule'
import { ModuleNameEnum } from '@/types/billing'

const LeavePolicy = () => {
    const navigate = useNavigate()
    const [searchQuery, setSearchQuery] = useState('')
    const [showCreatePrompt, setShowCreatePrompt] = useState(false)
    const [openCreateModal, setOpenCreateModal] = useState(false)
    const [searchParams] = useSearchParams()

    const { selectedAccount, setShowUpgradeModal, setCurrentModule } = useAuthContext()
    const { enabled: isLeaveEnabled } = useAccessToModule({ module: ModuleNameEnum.PAYROLL })

    const { data, isLoading } = useGetExpenseApprovalPolicies({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            module: 'leave',
        },
    })
    const expenseApprovalPolicies = useMemo(() => data ?? [], [data])

    const handleSearchApprovalPolicies = () => {
        return expenseApprovalPolicies
            ?.filter(policy => policy.policies.length > 0)
            ?.map(policy =>
                policy?.policies?.filter(policy_data => {
                    if (policy_data?.policy_name?.toLowerCase().includes(searchQuery.toLowerCase())) {
                        return policy_data
                    }
                })
            )
            .filter(arr => arr.length > 0)
            ?.sort((a, b) => {
                const aHasActive = a.some(item => item.active === true)
                const bHasActive = b.some(item => item.active === true)
                return Number(bHasActive) - Number(aHasActive)
            }) as ApprovalPolicy[][]
    }

    useEffect(() => {
        handleSearchApprovalPolicies()
    }, [searchQuery])
    useEffect(() => {
        if (searchParams.get('from') === 'noPolicy') {
            setOpenCreateModal(true)
        }
    }, [searchParams])

    if (isLoading)
        return (
            <div className='grid place-items-center'>
                <ProgressBar allWidth={false} />
            </div>
        )
    return (
        <>
            <div className='flex items-center gap-2 '>
                <button onClick={() => navigate(-1)} className='font-medium '>
                    Settings
                </button>
                <ChevronRight size={18} className='text-gray7' />
                <span className='text-sm text-gray1 '>Approval policy</span>
            </div>

            <main>
                <section className='flex justify-between flex-col gap-6 lg:gap-[10rem] lg:flex-row  items-start lg:items-center mt-10 px-2 lg:px-0'>
                    <div>
                        <h1 className='text-lg font-medium text-dark opacity-95 '>Settings</h1>
                        <p className='text-[#838383] text-sm '>
                            Manage appearance and approval policies for leave issuing
                        </p>
                    </div>

                    <div className='flex lg:justify-center  items-center gap-3 w-full lg:max-w-sm'>
                        <Input
                            leftIcon={<CiSearch className='text-gray7/50' fontSize={25} strokeWidth={1.2} />}
                            onChange={e => setSearchQuery(e.target.value)}
                            placeholder='Search approval policies'
                            className='h-10 bg-white border-gray6/60 placeholder:text-sm !pl-10 py-6  border-2 '
                        />

                        <button title='Icon' className='bg-gray6 p-1.5 h-9 aspect-square rounded-md'>
                            <AiOutlinePlus
                                onClick={() => {
                                    if (!isLeaveEnabled) {
                                        setShowUpgradeModal(true)
                                        setCurrentModule(ModuleNameEnum.PAYROLL)
                                        return
                                    }

                                    if (expenseApprovalPolicies?.length > 0) {
                                        setShowCreatePrompt(true)
                                        return
                                    }
                                    setOpenCreateModal(true)
                                }}
                                className='w-full h-full'
                                color={'#454ADE'}
                                strokeWidth={2}
                            />
                        </button>
                    </div>
                </section>

                <section className={`mt-7 px-3 mb-8 `}>
                    {handleSearchApprovalPolicies()?.length > 0 ? (
                        handleSearchApprovalPolicies()?.map((policy, i) => {
                            return (
                                <div className='' key={i}>
                                    <ApprovalPolicyListCard module={SupportedPolicyConsts.leave} policies={policy} />
                                </div>
                            )
                        })
                    ) : handleSearchApprovalPolicies()?.length === 0 && searchQuery?.length > 0 ? (
                        <div className='flex justify-center items-center flex-col'>
                            <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                            <span className='text-2xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                            <span className='text-center text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                Spend control not found in this record. Please try a different filter or contact support
                                for assistance
                            </span>
                        </div>
                    ) : (
                        <></>
                    )}
                </section>
                {handleSearchApprovalPolicies()?.length > 0 && (
                    <div className='mt-7'>
                        <button
                            className='flex items-center border border-gray5/80 rounded-md gap-2 p-2 text-sm text-brand bg-white'
                            onClick={() => {
                                if (expenseApprovalPolicies?.length > 0) {
                                    setShowCreatePrompt(true)
                                    return
                                }
                                setOpenCreateModal(true)
                            }}
                        >
                            <CgAddR strokeWidth={0.01} />
                            New approval policy
                        </button>
                    </div>
                )}

                {expenseApprovalPolicies?.length == 0 && (
                    <EmptyStates
                        description={`Set up your leave approval rules to get started.`}
                        image={notFound}
                        title='No Leave Approval Policy :('
                    />
                )}
                {showCreatePrompt && (
                    <CreateNewPolicyPrompt
                        module={SupportedPolicyConsts.leave}
                        closeModal={() => setShowCreatePrompt(false)}
                        action={() => {
                            setShowCreatePrompt(false)
                            setOpenCreateModal(true)
                        }}
                    />
                )}
                {openCreateModal && (
                    <CreatePolicyModal
                        module={SupportedPolicyConsts.leave}
                        closeModal={() => setOpenCreateModal(false)}
                    />
                )}
            </main>
        </>
    )
}

export default LeavePolicy
