import { DedicatedBankAccountType } from '@/types/accounts'
import { formatAmount } from '@/utils/money'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as AccountsIcon } from '@/assets/accounts-icon.svg'
import { Menu } from '@headlessui/react'
import { TfiAngleDown } from 'react-icons/tfi'
import FundAccountModal from '@/components/Modals/Accounts/FundAccountNew'
import TransferModal from '@/components/Modals/Accounts/TransferModalNew'
import { useState } from 'react'
import { isStagingEnvironment } from '@/utils/utils'

type Props = {
    account: DedicatedBankAccountType
    bankAccounts: DedicatedBankAccountType[]
}
export default function AccountCard({ account, bankAccounts }: Props) {
    const navigate = useNavigate()

    const [openFundModal, setOpenFundModal] = useState(false)
    const [openTransferModal, setOpenTransferModal] = useState(false)

    const accountDetails = [
        {
            name: 'Account number',
            desc: account?.account_number,
        },
        {
            name: 'Account name',
            desc: account?.account_name,
        },
        {
            name: 'Bank name',
            desc: account?.bank_name,
        },
    ]

    return (
        <div className={`relative border border-gray2/30 bg-white rounded-xl p-4`}>
            <div className='flex items-center gap-5'>
                <AccountsIcon />
                <h3 className='font-medium text-md text-gray7 capitalize'>
                    {account?.nickname?.toLowerCase() ?? account?.account_name?.toLowerCase()}
                </h3>
            </div>

            <p className='font-medium text-lg mt-4 opacity-80'>{formatAmount(account?.balance)}</p>
            <div className='border-t border-gray2/30 mt-4 pt-3'>
                <div className='flex gap-4 lg:flex-wrap xl:flex-nowrap'>
                    <Menu
                        as='div'
                        className='border border-gray2/60 bg-gray2/10 p-1 px-1.5 rounded text-xs font-medium text-gray7 flex items-center gap-2'
                    >
                        <Menu.Button className='flex items-center gap-2'>
                            Transact
                            <TfiAngleDown className='w-2 h-2 stroke-2' />
                        </Menu.Button>
                        <Menu.Items className='w-32 flex flex-col absolute rounded-lg justify-center border border-gray6 bg-white mt-2 z-10'>
                            <Menu.Item>
                                <span
                                    onClick={() => setOpenFundModal(true)}
                                    className='p-3 py-2 text-sm cursor-pointer'
                                >
                                    Fund account
                                </span>
                            </Menu.Item>
                            <Menu.Item>
                                <span
                                    onClick={() => setOpenTransferModal(true)}
                                    className='p-3 py-2 text-sm cursor-pointer'
                                >
                                    Send money
                                </span>
                            </Menu.Item>
                        </Menu.Items>
                    </Menu>
                    <button
                        aria-label='Bank details'
                        className='border border-gray2/60 bg-gray2/10 p-1 px-1.5 rounded text-xs font-medium text-gray7 w-full sm:w-fit 2xl:w-full'
                        onClick={() => navigate(account?._id ?? account?.id)}
                    >
                        Bank details
                    </button>
                    {isStagingEnvironment() && (
                        <button
                            aria-label='Statement'
                            className='border border-gray2/60 bg-gray2/10 p-1.5 px-2.5 rounded text-xs font-medium text-gray7 w-full sm:w-fit  2xl:w-full'
                        >
                            Statement
                        </button>
                    )}
                </div>
            </div>
            {openFundModal && (
                <FundAccountModal
                    accountDetails={accountDetails}
                    account={account}
                    bankAccounts={bankAccounts}
                    closeModal={() => setOpenFundModal(false)}
                />
            )}
            {openTransferModal && (
                <TransferModal
                    bankAccounts={bankAccounts}
                    account={account}
                    closeModal={() => setOpenTransferModal(false)}
                />
            )}
        </div>
    )
}
