export enum EventEnum {
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    REIMBURSED = 'REIMBURSED',
    CREATED = 'CREATED',
    DRAFTED = 'DRAFTED',
    PAID = 'PAID',
    SCHEDULED = 'SCHEDULED',
    PROCESSING = 'PROCESSING',
    DENIED = 'DENIED',
    CANCELLED = 'CANCELLED',
}

export enum SourceType {
    Wallet = 'Wallet',
    DepositAccount = 'DepositAccount',
}
