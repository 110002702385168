import { Button } from '@/components'

import ModalWrapper from '../ModalWrapper'
import { AlertTriangle } from 'lucide-react'

type Props = {
    closeModal: () => void
}
const RemoveAdvancedReceiptRule = ({ closeModal }: Props) => {
    return (
        <ModalWrapper closeModal={closeModal}>
            <main className='h-[19.5rem] lg:h-auto w-screen lg:w-[30rem]'>
                <section className='m-6'>
                    <div className='flex items-center gap-4'>
                        <span className='bg-errorRed/10 p-2 rounded-full'>
                            <AlertTriangle className='text-errorRed  w-6 h-6' />
                        </span>
                        <h3 className='font-medium text-lg'>Remove advanced receipt rule</h3>
                    </div>

                    <div className='space-y-5 mt-5'>
                        <p className='text-sm text-gray7'>
                            Are you sure you want to delete these advanced receipt rules? This action will remove all
                            custom receipt requirements based on amount.
                        </p>
                        <p className='text-sm font-medium'>Are you sure you still want to delete this rule?</p>
                    </div>
                </section>
                <div className='mt-5 border-t border-gray5'>
                    <div className='flex gap-5 justify-end mr-6 my-4'>
                        <Button
                            size={'lg'}
                            className='bg-transparent text-[#202020] hover:bg-transparent text-xs min-w-[120px]'
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </Button>

                        <Button size={'lg'} variant={'error'} className=' text-white text-xs min-w-[120px] '>
                            Yes, delete rule
                        </Button>
                    </div>
                </div>
            </main>
        </ModalWrapper>
    )
}

export default RemoveAdvancedReceiptRule
