import { DeleteIcon, ProfileEditIcon } from '@/assets/assets.export'
import { ReactComponent as MoveRight } from '@/assets/arrow-right-dot.svg'
import { Switch } from '@headlessui/react'
import React from 'react'
import { SupportedPolicyConsts } from '@/types/approval-policy'
import CreateAccountingPolicyModal from '@/components/Modals/ApprovalPolicy/CreatePolicyModal/CreateAccountingPolicyModal'
import RemoveAdvancedReceiptRule from '@/components/Modals/Accounting/RemoveAdvancedReceiptRule'

const AccountingRules = () => {
    const [openCreateModal, setOpenCreateModal] = React.useState(false)
    const [deletePrompt, setDeletePrompt] = React.useState(false)

    return (
        <section className='mb-5 bg-white rounded-xl p-7 flex-1'>
            <div className='border-gray/40 border-b pb-5'>
                <h2 className='font-medium'>Accounting rules</h2>
                <p className='text-sm text-gray7'>Set conditions to export or sync transactions</p>
            </div>

            <div className='my-8 space-y-6'>
                <ToggleRules
                    name='Restrict syncing to linked accounts'
                    desc='Enable this to ensure transactions can only be synced if they are linked to one of the uploaded / integrated accounts. Unlinked transactions will not be eligible for syncing or exporting'
                />
                <ToggleRules
                    name='Restrict syncing to categorized transactions'
                    desc='Enable this to ensure transactions can only be synced if they are linked to a Bizpend category. Uncategorized transactions will not be eligible for syncing or exporting.'
                />
                <ToggleRules
                    name='Require receipts'
                    desc='Enable this to ensure transactions require an attached receipt before they can be exported. Receipt requirements can be customized in settings based on amount.'
                />
            </div>

            <button onClick={() => setOpenCreateModal(true)} className='font-medium text-brand text-sm'>
                Add advanced receipt rules
            </button>

            <section className='mt-14'>
                <div className='border-gray/40 border-b pb-3 flex justify-between items-center'>
                    <h2 className='font-medium'>Advanced receipt rules</h2>
                    <div className='flex items-center gap-3'>
                        <ProfileEditIcon className='w-5 h-5 cursor-pointer' />
                        <DeleteIcon onClick={() => setDeletePrompt(true)} className='w-7 h-7 cursor-pointer' />
                    </div>
                </div>

                <div className='mt-8 space-y-4'>
                    <div className='border border-gray/40 rounded-lg p-4 py-5 flex justify-between'>
                        <span className='flex items-center gap-3 font-medium text-gray7'>
                            <span>₦ 0.00</span>
                            <span>
                                <MoveRight />
                            </span>
                            <span>₦ 50,000.00</span>
                        </span>
                        <span className='font-medium text-errorRed text-sm'>Required</span>
                    </div>
                    <div className='border border-gray/40 rounded-lg p-4 py-5 flex justify-between'>
                        <span className='flex items-center gap-3 font-medium text-gray7'>No condition met?</span>
                        <span className='font-medium text-gray7 text-sm'>Not required</span>
                    </div>
                </div>
            </section>

            {openCreateModal && (
                <CreateAccountingPolicyModal
                    module={SupportedPolicyConsts.accounting}
                    closeModal={() => setOpenCreateModal(false)}
                />
            )}

            {deletePrompt && <RemoveAdvancedReceiptRule closeModal={() => setDeletePrompt(false)} />}
        </section>
    )
}

export default AccountingRules

type Props = {
    name: string
    desc: string
}
const ToggleRules = ({ desc, name }: Props) => {
    const [toggle, setToggle] = React.useState(false)
    return (
        <div className=' gap-24 flex items-center justify-between w-full'>
            <div>
                <h2 className='font-medium text-[0.9rem] '>{name}</h2>
                <p className='text-[0.85rem] text-gray7'>{desc}</p>
            </div>
            <div>
                <Switch
                    checked={toggle}
                    onChange={setToggle}
                    className={`relative inline-flex h-6 w-11 items-center rounded-full ${
                        toggle ? 'bg-brand' : 'bg-[#EFEFF9]'
                    }`}
                >
                    <span
                        className={`${
                            !toggle ? 'translate-x-1 bg-brand' : 'translate-x-6 bg-white'
                        } inline-block h-4 w-4 transform rounded-full  transition`}
                    />
                </Switch>
            </div>
        </div>
    )
}
