import { useEffect, useMemo, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { CgAddR } from 'react-icons/cg'
import { useAuthContext } from '@/context'
import { useGetExpenseApprovalPolicies } from '@/services/settings/queries'
import ProgressBar from '@/components/ProgressBar'
import { AiOutlinePlus } from 'react-icons/ai'
import notFound from '@/assets/not-found.svg'
import EmptyStates from '@/components/EmptyStates'
import CreateNewPolicyPrompt from '@/components/Modals/Settings/CreateNewPolicyPrompt'
import { Input } from '@/components'

import ApprovalPolicyListCard from '@/components/ApprovalPolicyBox/ApprovalPolicyListCard'
import { ApprovalPolicy, SupportedPolicyConsts } from '@/types/approval-policy'
import CreatePolicyModal from '@/components/Modals/ApprovalPolicy/CreatePolicyModal/CreatePolicyModal'
import { useSearchParams } from 'react-router-dom'

const ExpenseApprovalPolicy = () => {
    const { selectedAccount } = useAuthContext()
    const [searchParams] = useSearchParams()

    const [searchQuery, setSearchQuery] = useState('')
    const [showCreatePrompt, setShowCreatePrompt] = useState(false)
    const [openCreateModal, setOpenCreateModal] = useState(false)

    const { data, isLoading } = useGetExpenseApprovalPolicies({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            module: 'expense',
        },
    })
    const expenseApprovalPolicies = useMemo(() => data ?? [], [data])

    const handleSearchApprovalPolicies = () => {
        return expenseApprovalPolicies
            ?.filter(policy => policy.policies.length > 0)
            ?.map(policy =>
                policy?.policies?.filter(policy_data => {
                    if (policy_data?.policy_name?.toLowerCase().includes(searchQuery.toLowerCase())) {
                        return policy_data
                    }
                })
            )
            .filter(arr => arr.length > 0)
            ?.sort((a, b) => {
                const aHasActive = a.some(item => item.active === true)
                const bHasActive = b.some(item => item.active === true)
                return Number(bHasActive) - Number(aHasActive)
            }) as ApprovalPolicy[][]
    }
    useEffect(() => {
        if (searchParams.get('from') === 'noPolicy') {
            setOpenCreateModal(true)
        }
    }, [searchParams])

    useEffect(() => {
        handleSearchApprovalPolicies()
    }, [searchQuery])

    if (isLoading) return <ProgressBar />
    return (
        <main className='pb-10'>
            <section className='flex justify-between flex-col gap-6 lg:gap-[10rem] lg:flex-row  items-start lg:items-center mt-6 px-2 lg:px-0'>
                <div>
                    <h1 className='text-lg font-medium text-dark opacity-95 '>Set approval policies</h1>
                    <p className='text-[#838383] text-sm '>
                        Assign admins and level of approvals to approve expense and reimbursements
                    </p>
                </div>

                <div className='flex lg:justify-center  items-center gap-3 w-full lg:max-w-sm'>
                    <Input
                        leftIcon={<CiSearch className='text-gray7/50' fontSize={25} strokeWidth={1.2} />}
                        onChange={e => setSearchQuery(e.target.value)}
                        placeholder='Search approval policies'
                        className='h-10 bg-white border-gray6/60 placeholder:text-sm !pl-10 py-6  border-2 '
                    />

                    <button aria-label='Icon' className='bg-gray6 p-1.5 h-9 aspect-square rounded-md'>
                        <AiOutlinePlus
                            onClick={() => {
                                if (expenseApprovalPolicies?.length > 0) {
                                    setShowCreatePrompt(true)
                                    return
                                }
                                setOpenCreateModal(true)
                            }}
                            className='w-full h-full'
                            color={'#454ADE'}
                            strokeWidth={2}
                        />
                    </button>
                </div>
            </section>

            <section className={`mt-7 px-3 mb-8 `}>
                {handleSearchApprovalPolicies()?.length > 0 ? (
                    handleSearchApprovalPolicies()?.map((policy, i) => {
                        return (
                            <div className='' key={i}>
                                <ApprovalPolicyListCard module={SupportedPolicyConsts.expense} policies={policy} />
                            </div>
                        )
                    })
                ) : handleSearchApprovalPolicies()?.length === 0 && searchQuery?.length > 0 ? (
                    <div className='flex justify-center items-center flex-col'>
                        <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                        <span className='text-2xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                        <span className='text-center text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                            Spend control not found in this record. Please try a different filter or contact support for
                            assistance
                        </span>
                    </div>
                ) : (
                    <></>
                )}
            </section>
            {handleSearchApprovalPolicies()?.length > 0 && (
                <div className='mt-7'>
                    <button
                        className='flex items-center border border-gray5/80 rounded-md gap-2 p-2 text-sm text-brand bg-white'
                        onClick={() => {
                            if (expenseApprovalPolicies?.length > 0) {
                                setShowCreatePrompt(true)
                                return
                            }
                            setOpenCreateModal(true)
                        }}
                    >
                        <CgAddR strokeWidth={0.01} />
                        New approval policy
                    </button>
                </div>
            )}

            {searchQuery.length === 0 && handleSearchApprovalPolicies()?.length > 0 && (
                <>
                    <EmptyStates
                        description={`Oops! It seems we couldn't find any matching results for your search. Please try again with different keywords or refine your filter criteria for better results.`}
                        image={notFound}
                        title={`You're yet to create an expense policy`}
                    />
                </>
            )}
            {showCreatePrompt && (
                <CreateNewPolicyPrompt
                    module={SupportedPolicyConsts.expense}
                    closeModal={() => setShowCreatePrompt(false)}
                    action={() => {
                        setShowCreatePrompt(false)
                        setOpenCreateModal(true)
                    }}
                />
            )}
            {openCreateModal && (
                <CreatePolicyModal
                    module={SupportedPolicyConsts.expense}
                    closeModal={() => setOpenCreateModal(false)}
                />
            )}
        </main>
    )
}

export default ExpenseApprovalPolicy
