import { useRef, useState } from 'react'
import ModalWrapper from '../ModalWrapper'
import { CancelIcon } from '@/assets/assets.export'
import { DedicatedBankAccountType } from '@/types/accounts'
import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import { Button } from '@/components/Button'
import { addDays, format } from 'date-fns'

type Props = {
    closeModal: () => void
    account: DedicatedBankAccountType
}
type FormType = {
    to: string
    from: string
}

export const GenerateAccountStatement = ({ closeModal }: Props) => {
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { isValid },
    } = useForm<FormType>({})
    const calendarRef1 = useRef<DatePicker>(null)
    const calendarRef2 = useRef<DatePicker>(null)
    const [step, setStep] = useState(1)

    const fromDate = watch('from')
    const toDate = watch('to')

    const onSubmit = (values: FormType) => {
        console.log(values)
    }
    return (
        <ModalWrapper>
            <form onSubmit={handleSubmit(onSubmit)} className=' min-h-[18rem] w-[25rem] md:w-[29rem] overflow-scroll'>
                <div
                    className={`flex items-center border-b border-[#DADCE0] p-3 px-5 justify-between ${step === 2 && 'hidden'}`}
                >
                    <h2 className='text-center text-lg font-semibold opacity-80'>Generate bank statement</h2>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer'
                        onClick={() => closeModal()}
                    />
                </div>
                {step === 1 && (
                    <>
                        <div className='mt-5 mx-6'>
                            <p className='font-semibold mb-4'>Select date range</p>
                            <fieldset className=' flex flex-col flex-1 mb-4'>
                                <label className='mb-1.5 text-sm' htmlFor='funding_method'>
                                    From
                                </label>
                                <Controller
                                    name='from'
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <CustomDatePicker
                                            ref={calendarRef1}
                                            placeholderText='DD/MM/YYYY'
                                            className='w-[70%]'
                                            onChange={date => {
                                                field.onChange(date)
                                                setValue('to', '')
                                            }}
                                            // minDate={}
                                            wrapperClassName='border-2'
                                            selected={fromDate as unknown as Date}
                                        />
                                    )}
                                />
                            </fieldset>
                            <fieldset className=' flex flex-col flex-1 '>
                                <label className='mb-1.5 text-sm' htmlFor='funding_method'>
                                    To
                                </label>
                                <Controller
                                    name='to'
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <CustomDatePicker
                                            ref={calendarRef2}
                                            placeholderText='DD/MM/YYYY'
                                            className='w-[70%]'
                                            onChange={date => {
                                                field.onChange(date)
                                            }}
                                            minDate={addDays(new Date(fromDate), 1)}
                                            wrapperClassName='border-2'
                                            selected={toDate as unknown as Date}
                                        />
                                    )}
                                />
                            </fieldset>
                        </div>
                        <div className='border-t border-gray4 flex mt-6 mb-3 justify-end items-center pt-2.5 px-4'>
                            <Button disabled={!isValid} className='bg-[#19C37D]' onClick={() => setStep(2)}>
                                Generate statement
                            </Button>
                        </div>
                    </>
                )}
                {step === 2 && (
                    <>
                        <div className='flex items-center flex-col justify-center h-full relative mx-12'>
                            <CancelIcon
                                className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer absolute -right-4 top-6'
                                onClick={() => closeModal()}
                            />
                            <div className='mt-10 mb-5'>
                                <svg
                                    className='checkmark w-[56px] h-[56px] rounded-full block'
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 52 52'
                                >
                                    <circle className='checkmark__circle' cx='26' cy='26' r='25' fill='none' />{' '}
                                    <path className='checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
                                </svg>
                            </div>
                            <p className='text-lg font-semibold text-center'>Bank statement generated successfully</p>
                            <p className='text-gray7 font-medium mt-2 text-center'>
                                A bank statement for the period of{' '}
                                <span className='text-[#202020] font-semibold'>
                                    {format(new Date(fromDate), 'dd/MM/yyyy')}
                                </span>{' '}
                                to{' '}
                                <span className='text-[#202020] font-semibold'>
                                    {format(new Date(toDate), 'dd/MM/yyyy')}
                                </span>{' '}
                                has been sent to your email
                            </p>
                        </div>
                    </>
                )}
            </form>
        </ModalWrapper>
    )
}
