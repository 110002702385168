import { ReactNode, createContext, useMemo } from 'react'

import { useAuthContext } from '.'

import { invoiceSidebarPermissions } from '@/layout/LayoutComponents/utils'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { useGetInvoiceItem } from '@/services/invoice/queries'
import { InvoiceItemType } from '@/types/invoice'

export type InvoiceItemsDefaultType = {
    invoiceItems: InvoiceItemType[]
}

const defaultValues: InvoiceItemsDefaultType = {
    invoiceItems: [],
}

export const InvoiceItemsContext = createContext(defaultValues)

export default function InvoiceItemsContextProvider({ children }: { children: ReactNode }) {
    const { selectedAccount } = useAuthContext()

    const hasPermission = useHasAccessPermisson(invoiceSidebarPermissions)

    const { data } = useGetInvoiceItem({
        enabled: hasPermission,
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
    })

    const invoiceItems = useMemo(() => data?.results ?? [], [data])

    return <InvoiceItemsContext.Provider value={{ invoiceItems }}>{children}</InvoiceItemsContext.Provider>
}
