import { CancelIcon, FolderIcon } from '@/assets/assets.export'
import ModalWrapper from '../ModalWrapper'
import { useEffect, useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'
import { useCreateVaultItem, useUpdateVaultItem } from '@/services/vault/mutations'
import { VaultItem, VaultItemVisibility, VaultListData } from '@/services/vault/types'
// import { useGetVaultItems } from '@/services/vault/queries'
import { toastHandler } from '@/components/utils/Toast'
import { capitalizeText } from '@/utils/string'
import SelectWIthChip from '@/components/SelectWithChip'
import { QueryOrgMembers } from '@/services/employees/types'
import { ActivateVaultModal } from './ActivateVaultModal'
import { useNavigate } from 'react-router-dom'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    rename?: boolean
    fileType?: string
    vault?: VaultListData
    item?: VaultItem
    orgMembers?: QueryOrgMembers
}

export const CreateNewFolder = ({ closeModal, rename = false, fileType, vault, item, orgMembers }: Props) => {
    const [formState, setFormState] = useState({
        parentFolder: '',
        name: item ? item.name : '',
        Visibility: '',
    })
    const [showPasswordModal, setShowPasswordModal] = useState(false)
    const navigate = useNavigate()

    const [selectedMembers, setSelectedMembers] = useState<{ label: string; value: string }[]>([])
    const [orgMembersData, setOrgMembersData] = useState<{ label: string; value: string }[]>([])
    const allow =
        [formState.parentFolder, formState.name, formState.Visibility].every(entry => entry.length > 0) &&
        (formState.Visibility === VaultItemVisibility.SHARED ? selectedMembers.length > 0 : true)

    const { mutate: updateFn, isLoading: isUpdating } = useUpdateVaultItem({
        onSuccess: () => {
            toastHandler({ message: `${capitalizeText(fileType ? 'file' : 'folder')} renamed successfully` })
            closeModal(false)
        },
    })

    const { mutate: createFn, isLoading: isCreating } = useCreateVaultItem({
        onSuccess: () => {
            toastHandler({ message: `Folder created successfully`, state: 'success' })
            navigate(`/dashboard/vault/documents${formState.Visibility === 'personal' ? '?access=personal' : ''}`)
            closeModal(false)
        },
    })
    // const { data } = useGetVaultItems({
    //     queryParams: {
    //         vault: vault?.id as string,
    //         parent: vault?.id as string,
    //     },
    //     enabled: !rename,
    // })

    const handleButton = () => {
        if (rename) {
            updateFn({
                name: formState.name,
                id: item?.id as string,
            })
            return
        }

        if (!rename) {
            // to make sure password is set before creating personal folders
            if (!vault?.is_password_set && formState.Visibility === 'personal') {
                setShowPasswordModal(true)
                return
            }

            createFn({
                name: formState.name,
                type: 'folder',
                vault: vault?.id,
                parent: formState.parentFolder,
                visibility: formState.Visibility,
                shared_with:
                    formState.Visibility === VaultItemVisibility.SHARED ? selectedMembers.map(memb => memb.value) : [],
            })
        }
    }

    useEffect(() => {
        if (orgMembers) {
            setOrgMembersData(
                orgMembers.map(orgMembers => ({
                    label: `${capitalizeText(orgMembers?.user?.first_name)} ${capitalizeText(orgMembers?.user?.last_name)}`,
                    value: orgMembers?.id,
                }))
            )
        }
    }, [orgMembers])

    return (
        <ModalWrapper variants={undefined} formId='folderForm' closeModal={() => closeModal(false)} shouldConfirmClose>
            <main className='max-h-[75vh] w-screen lg:w-[31rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                    <span className='flex-1 text-xl font-medium ml-5'>
                        {rename ? 'Rename' : 'Create new'} {fileType ? 'file' : 'folder'}
                    </span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <form id='folderForm' onSubmit={e => e.preventDefault()}>
                    <div className='px-4 lg:px-4 p-7 pt-5 overflow-y-scroll'>
                        <div className={`flex flex-col flex-1 ${rename && 'hidden'}`}>
                            <label className='mb-3' htmlFor='folder'>
                                Choose a folder to save it in
                            </label>
                            <Select
                                name='folder'
                                onValueChange={value => {
                                    setFormState(prevState => ({
                                        ...prevState,
                                        parentFolder: value,
                                    }))
                                }}
                                value={formState.parentFolder}
                            >
                                <SelectTrigger className=' text-base h-[3rem]  text-[#5E5E5E] border-gray3 flex '>
                                    {formState?.parentFolder ? <SelectValue /> : <span>Select root folder</span>}
                                </SelectTrigger>
                                <SelectContent className='max-h-[12rem] overflow-y-scroll w-[27.5rem]'>
                                    <SelectItem value={vault?.id as string} className='pl-3'>
                                        <div className='flex items-center gap-3'>
                                            <FolderIcon className={`w-4 h-4`} />
                                            <div className='flex flex-col items-start'>
                                                <h6 className='text sm'>Root Vault</h6>
                                            </div>
                                        </div>
                                    </SelectItem>
                                    {/* {data
                                        ?.filter(item => item.type === 'folder')
                                        .map(num => (
                                            <SelectItem value={num?.id} className='pl-3' key={num?.id}>
                                                <div className='flex items-center gap-3'>
                                                    <FolderIcon className={`w-4 h-4`} />
                                                    <div className='flex flex-col items-start'>
                                                        <h6 className='text sm'>{num?.name}</h6>
                                                    </div>
                                                </div>
                                            </SelectItem>
                                        ))} */}
                                </SelectContent>
                            </Select>
                        </div>
                        <div className={`flex flex-col flex-1 gap-3 ${rename ? 'mt-2' : 'mt-6'}`}>
                            <p className='w-full text-right -mb-10 text-sm text-gray7'>
                                {formState.name.length}/50 characters
                            </p>
                            <label className='flex items-center gap-1'>
                                {fileType ? 'File' : 'Folder'} name
                                <span className='text-[red]'>*</span>
                            </label>
                            <Input
                                type='text'
                                placeholder={`Name this ${fileType ? 'file' : 'folder'}`}
                                name='name'
                                value={formState.name}
                                onChange={e => {
                                    if (e.target.value.length === 51) return
                                    setFormState(prev => ({ ...prev, name: e.target.value }))
                                }}
                                className='border-[#DADCE0]  h-[3rem]'
                            />
                        </div>
                        <div className={`flex flex-col flex-1 mt-3 ${rename && 'hidden'}`}>
                            <label className='mb-3' htmlFor='folder'>
                                Visibility
                            </label>
                            <Select
                                name='folder'
                                onValueChange={value => {
                                    setFormState(prevState => ({
                                        ...prevState,
                                        Visibility: value,
                                    }))
                                }}
                                value={formState.Visibility}
                            >
                                <SelectTrigger className=' text-base h-[3rem]  text-[#5E5E5E] border-gray3 flex '>
                                    {formState?.Visibility ? <SelectValue /> : <span>Select visibility type</span>}
                                </SelectTrigger>
                                <SelectContent className='max-h-[12rem] overflow-y-scroll w-[27.5rem]'>
                                    <SelectItem value={VaultItemVisibility.GENERAL} className='pl-3'>
                                        General
                                    </SelectItem>
                                    <SelectItem value={VaultItemVisibility.PERSONAL} className='pl-3'>
                                        Personal
                                    </SelectItem>
                                    {/* <SelectItem value={VaultItemVisibility.SHARED} className='pl-3'>
                                        Shared
                                    </SelectItem> */}
                                </SelectContent>
                            </Select>
                        </div>
                        {formState.Visibility === VaultItemVisibility.SHARED && (
                            <div className='flex flex-col flex-1 gap-3 mt-4'>
                                <label className='flex items-center gap-1'>
                                    Share access with
                                    <span className='text-errorRed'>*</span>
                                </label>
                                <div className=''>
                                    <SelectWIthChip
                                        options={orgMembersData}
                                        placeholder='Select from the list of users'
                                        onChange={members_details => {
                                            setSelectedMembers(members_details)
                                        }}
                                        value={selectedMembers}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </form>
                <div className='flex items-center border-t border-[#DADCE0] p-4 justify-between'>
                    <>&nbsp;</>
                    <Button
                        disabled={rename ? formState.name.length <= 0 || isUpdating : !allow || isCreating}
                        onClick={handleButton}
                        loading={isCreating || isUpdating}
                    >
                        {rename ? 'Update' : 'Create'} {fileType ? 'File' : 'Folder'}
                    </Button>
                </div>
                {showPasswordModal && <ActivateVaultModal closeModal={setShowPasswordModal} vault_id={vault?.id} />}
            </main>
        </ModalWrapper>
    )
}
