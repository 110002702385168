import { CrownBrownLogo } from '@/assets/assets.export'
import { useBudgetContext, useCurrencyContext } from '@/context'
import { formatAmountNoSymbol } from '@/utils/money'
import { useNavigate, useParams } from 'react-router-dom'
import { ComputePercent } from '../utils'
import { Budget } from '@/types/budget'

type BudgetCardProps = {
    budget: Budget
}
const BudgetCards = ({ budget }: BudgetCardProps) => {
    const navigate = useNavigate()
    const { budget_id } = useParams()

    const { color, bgColor, percentage } = ComputePercent(budget)

    const { setBudgetObj } = useBudgetContext()
    const { selectedCurrencyInfo } = useCurrencyContext()

    const currencySymbol = selectedCurrencyInfo?.(budget?.currency?._id)?.symbol

    const handleSetBudget = () => {
        if (budget_id) return
        setBudgetObj(budget)
        navigate(`/dashboard/budget/${budget._id}`)
    }
    return (
        <div
            className='border border-[#DADCE0] rounded-2xl p-5 cursor-pointer overflow-hidden'
            onClick={handleSetBudget}
        >
            <div className='flex justify-between items-center mb-4'>
                <div className='flex items-center gap-2 relative'>
                    <h1 className='text-lg font-medium'>{budget.name ?? 'N/A'}</h1>
                </div>
                <div className='flex items-center gap-4'>
                    <span
                        className='px-3 py-1 rounded-full text-sm'
                        style={{ color: color, backgroundColor: bgColor, border: `1px solid ${color}` }}
                    >
                        {percentage.toFixed(1)}%
                    </span>
                </div>
            </div>
            <div className='flex items-center justify-between flex-wrap gap-4'>
                <div className='flex items-center gap-5'>
                    <div className=''>
                        <h2 className='text-[#5E5E5E] mb-2  text-sm'>Allocated</h2>
                        <p className='font-semibold'>
                            {currencySymbol}
                            {formatAmountNoSymbol(budget?.allocated_amount)}
                        </p>
                    </div>
                    <div className=''>
                        <h2 className='text-[#5E5E5E] mb-2 text-sm'>Spent</h2>
                        <p className=''>
                            {currencySymbol}
                            {formatAmountNoSymbol(Number(budget?.allocated_amount) - Number(budget?.available_amount))}
                        </p>
                    </div>
                    <div className=''>
                        <h2 className='text-[#5E5E5E] mb-2 text-sm'>Available</h2>
                        <p className=''>
                            {currencySymbol}
                            {formatAmountNoSymbol(budget?.available_amount)}
                        </p>
                    </div>
                </div>
            </div>
            <div className='mt-5 border-t border-gray8 flex justify-between items-center pt-4'>
                <p className='text-gray7 text-sm font-medium'>
                    {budget.accounts.length} employee{budget.accounts.length > 1 ? 's' : ''}
                </p>
                <div className='bg-offwhite border border-gray8 rounded-lg px-3 py-2.5 flex items-center gap-2'>
                    <CrownBrownLogo />
                    <p className='text-sm'>
                        {budget.manager[0]?.user?.first_name} {budget.manager[0]?.user?.last_name}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default BudgetCards
