import { ShareAccessIcon } from '@/assets/assets.export'
import { FaEllipsisVertical } from 'react-icons/fa6'
import { Menu } from '@headlessui/react'
import { PenLine } from 'lucide-react'
import { FileDown } from 'lucide-react'
import { MdOutlineMoveToInbox } from 'react-icons/md'
import { Trash2 } from 'lucide-react'
import { useMemo, useState } from 'react'
import { CreateNewFolder } from '@/components/Modals/Vault/CreateNewFolder'
import { DeleteModal } from '@/components/Modals/Vault/DeleteModal'
import { ShareAccessModal } from '@/components/Modals/Vault/ShareAccessModal'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useAuthContext, useVaultContext } from '@/context'
import { VaultItem } from '@/services/vault/types'
import { differenceInHours, format, formatDistanceToNow } from 'date-fns'
import { capitalizeText } from '@/utils/string'
import { MoveFileModal } from '@/components/Modals/Vault/MoveFileModal'
import { useNavigate } from 'react-router-dom'
import { VerifyVaultPassword } from '@/components/Modals/Vault/VerifyVaultPassword'
import folderRec from '@/assets/FolderRectangle.svg'
import folderLock from '@/assets/folder-lock.svg'
import { History } from 'lucide-react'
import { RestoreVaultItem } from '@/components/Modals/Vault/RestoreVaultItem'
// import { useGetZippedFolder } from '@/services/vault/mutations'
// import { toastHandler } from '@/components/utils/Toast'
import { fetchAndDownloadZip } from '@/utils/utils'
import { BASE_URL } from '@/services/api'

type FolderContainerProps = {
    folder: VaultItem
}
export const FolderContainer = ({ folder }: FolderContainerProps) => {
    const [showRename, setShowRename] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showShareAccess, setShowShareAccess] = useState(false)
    const [showRestore, setShowRestore] = useState(false)
    const [showMove, setShowMove] = useState(false)
    const [showVerifyPassword, setShowVerifyPassword] = useState(false)
    const navigate = useNavigate()
    const { setSelectedFolder, selectedFolder } = useVaultContext()

    const { selectedAccount } = useAuthContext()
    const { data: employees } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: `ACTIVE`,
        },
    })
    // const { mutate: downloadZipFn } = useGetZippedFolder({
    //     onSuccess: () => {
    //         // downloadZipFromResponse(data)
    //         toastHandler({ message: 'Folder downloaded as zip', state: 'success' })
    //     },
    // })
    const notInRootVault = folder?.parent !== folder?.vault

    const orgMembers = useMemo(() => employees ?? [], [employees])
    const owner = orgMembers.find(memb => memb?.id === folder.owner)

    return (
        <div
            className='bg-transparent p-3 rounded-xl cursor-pointer relative'
            onClick={() => {
                if (folder.deleted) return
                const isAParent = folder.parent === folder.vault
                if (isAParent) {
                    setSelectedFolder(folder)
                }
                if (folder.visibility === 'personal') {
                    setShowVerifyPassword(true)
                } else {
                    navigate(`?folder=${folder?.id}${folder.parent !== folder.vault ? `&parent=${folder.parent}` : ''}`)
                }
            }}
        >
            <img src={folderRec} alt='folder' className='absolute bottom-0 left-0 w-full h-full lg:h-auto' />
            {folder.visibility === 'personal' && (
                <div className='absolute -bottom-1 -right-1  bg-white rounded-full p-1 pl-2.5 pb-1'>
                    <img src={folderLock} alt='folder' className='' />
                </div>
            )}
            <div className='flex justify-between gap-2 w-full'>
                <div className='flex items-center gap-2 w-4/5'>
                    <p className='text-xs font-medium w-full truncate'>{capitalizeText(folder?.name)}</p>
                </div>
                <Menu as='div' className='relative'>
                    <Menu.Button
                        className='rotate-90'
                        onClick={e => {
                            e.stopPropagation()
                        }}
                    >
                        <FaEllipsisVertical color='#A4A4A4' />
                    </Menu.Button>
                    <Menu.Items
                        onClick={e => {
                            e.stopPropagation()
                        }}
                        className='shadow-xl w-[185px] flex flex-col absolute -right-9 p-3 px-0 rounded-lg justify-center border border-[#DADCE0] bg-white mt-2 z-10'
                    >
                        {!folder.deleted ? (
                            <>
                                <Menu.Item>
                                    <span
                                        className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm'
                                        onClick={() => {
                                            setShowRename(true)
                                        }}
                                    >
                                        <PenLine size={16} />
                                        Rename folder
                                    </span>
                                </Menu.Item>
                                <Menu.Item>
                                    <span
                                        className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm'
                                        onClick={() => {
                                            // downloadZipFn({
                                            //     itemId: folder.id,
                                            // })

                                            fetchAndDownloadZip(
                                                folder.id,
                                                `${BASE_URL}/vault/item/download-zip`,
                                                folder.name
                                            )
                                        }}
                                    >
                                        <FileDown size={16} />
                                        Download as zip
                                    </span>
                                </Menu.Item>
                                <Menu.Item>
                                    <span
                                        className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm'
                                        onClick={() => {
                                            setShowShareAccess(true)
                                        }}
                                    >
                                        <ShareAccessIcon className='scale-[.9]' />
                                        Share access
                                    </span>
                                </Menu.Item>
                                <Menu.Item>
                                    <span
                                        className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm'
                                        onClick={() => {
                                            setShowMove(true)
                                        }}
                                    >
                                        <MdOutlineMoveToInbox size={16} className='-rotate-90' />
                                        Move folder
                                    </span>
                                </Menu.Item>
                            </>
                        ) : (
                            <Menu.Item>
                                <span
                                    className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm'
                                    onClick={() => {
                                        setShowRestore(true)
                                    }}
                                >
                                    <History size={16} />
                                    Restore folder
                                </span>
                            </Menu.Item>
                        )}
                        <Menu.Item>
                            <div className='border-t border-gray8 mt-1'>
                                <span
                                    className='px-4 text-errorRed py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm mt-1'
                                    onClick={() => {
                                        setShowDelete(true)
                                    }}
                                >
                                    <Trash2 size={16} />
                                    Delete folder
                                </span>
                            </div>
                        </Menu.Item>
                    </Menu.Items>
                </Menu>
            </div>
            <p className='text-xs font-medium text-[#838383] mt-1'>MB</p>
            <div className='hidden flex-col gap-2 mt-3'>
                <div className='flex justify-between items-center text-xs'>
                    <p className='text-gray7 font-medium'>Owned by</p>
                    <p className='text-[#202020] font-semibold text-right'>
                        {capitalizeText(owner?.user?.first_name)} {capitalizeText(owner?.user?.last_name)}
                    </p>
                </div>
                <div className='flex justify-between items-center text-xs'>
                    <p className='text-gray7 font-medium'>Created</p>
                    <p className='text-[#202020] font-semibold truncate w-[100px] text-right'>
                        {differenceInHours(new Date(), new Date(folder?.createdAt)) > 23
                            ? format(new Date(folder?.createdAt), 'MMM dd, yyyy')
                            : formatDistanceToNow(new Date(folder?.createdAt), { addSuffix: true })}
                    </p>
                </div>
                <div className='flex justify-between items-center text-xs'>
                    <p className='text-gray7 font-medium'>Parent folder</p>
                    <p className='text-[#202020] font-semibold text-right'>
                        {!notInRootVault ? 'Root Vault' : selectedFolder?.name}
                    </p>
                </div>
            </div>
            {showRename && (
                <CreateNewFolder orgMembers={orgMembers} closeModal={setShowRename} rename={true} item={folder} />
            )}
            {showRestore && <RestoreVaultItem closeModal={setShowRestore} file={folder} fileType={'folder'} />}
            {showDelete && <DeleteModal closeModal={setShowDelete} file={folder} fileType={'folder'} />}
            {showShareAccess && (
                <ShareAccessModal
                    closeModal={setShowShareAccess}
                    file={folder}
                    fileType='folder'
                    orgMembers={orgMembers}
                />
            )}
            {showMove && <MoveFileModal closeModal={setShowMove} file={folder} actionType={'move'} />}
            {showVerifyPassword && <VerifyVaultPassword closeModal={setShowVerifyPassword} folder={folder} />}
        </div>
    )
}
