import React, { useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { CancelIcon } from '@/assets/assets.export'
import { PaymentProfile } from '@/types/payroll'
import { Button } from '@/components/Button'
import { formatAmount, formatAmountNoSymbol } from '@/utils/money'
import { Minus, Plus } from 'lucide-react'
import { useUpdatePayrollPayout } from '@/services/payroll/mutations'
import moment from 'moment'
import { calculateBonusAndDeductionSum } from '@/modules/dashboard/Payroll/utils'

type BulkPayrollEditProps = {
    closeModal: () => void
    data: PaymentProfile[]
}
export const BulkPayrollEdit = ({ closeModal, data }: BulkPayrollEditProps) => {
    const [payrollList, setPayrollList] = useState(data)

    const { mutate, isLoading } = useUpdatePayrollPayout({})

    const handleDaysWorked = (type: 'increment' | 'decrement', id: string) => {
        setPayrollList(fields =>
            fields.map(data => {
                const totalWorkableDays = moment(data.current_period_end).diff(
                    moment(data.current_period_start),
                    'days'
                )
                const daysWorked = Number(data.days_worked) + (type === 'increment' ? 1 : -1)

                const proratedAmount = (Number(data.gross_salary) / totalWorkableDays) * Number(daysWorked)
                const bonus = calculateBonusAndDeductionSum(data.bonuses ?? [], 'amount', data.gross_salary)
                const deductions = calculateBonusAndDeductionSum(data.deductions ?? [], 'amount', data.gross_salary)
                if (data._id === id) {
                    return {
                        ...data,
                        days_worked: daysWorked.toString(),
                        current_payout: (proratedAmount + bonus - deductions).toFixed(2),
                    }
                }
                return data
            })
        )
    }

    return (
        <ModalWrapper formId='bulkEdit' closeModal={() => closeModal()} shouldConfirmClose>
            <main className=' w-screen lg:w-[37rem] h-[26rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 justify-between'>
                    <span className='flex-1 text-xl font-medium ml-5'>Make bulk edit</span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal()}
                    />
                </section>
                <form id='bulkEdit' className='py-6 px-7 h-full' onSubmit={e => e.preventDefault()}>
                    <div className='border border-[#DADCE0] rounded-lg w-full overflow-y-scroll'>
                        <table className='text-sm text-[#202020] min-w-[32rem] md:w-full'>
                            <thead className='border-b-[0.5px] border-[#DADCE0] bg-[#F9F9F9]'>
                                <tr>
                                    <th className='w-[40%] text-left py-3 pl-4 text-xs font-semibold'>User</th>
                                    <th className='text-center py-3 text-xs font-semibold'>Days worked</th>
                                    <th className='text-center py-3 text-xs font-semibold'>Current payout</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payrollList.map(profile => (
                                    <tr className='border-b border-gray8' key={(profile?._id ?? profile?.id) as string}>
                                        <td className='py-[7px] pl-4 whitespace-nowrap text-[13px]'>
                                            {profile.employee.user.first_name} {profile.employee.user.last_name}
                                        </td>
                                        <td className='text-center py-[7px]'>
                                            {' '}
                                            <div className='flex mx-auto border border-[#DADCE0] justify-between rounded-lg gap-3 w-[90px] text-sm h-8 items-center px-2'>
                                                <button
                                                    aria-label='Icon'
                                                    disabled={Number(profile.days_worked) == 1}
                                                    className='disabled:opacity-30'
                                                >
                                                    <Minus
                                                        color='#5E5E5E'
                                                        className='cursor-pointer'
                                                        onClick={() =>
                                                            handleDaysWorked(
                                                                'decrement',
                                                                profile._id.toString() ?? profile?.id ?? ''
                                                            )
                                                        }
                                                        size={16}
                                                    />
                                                </button>
                                                {profile.days_worked}
                                                <button aria-label='Icon' className='disabled:opacity-30'>
                                                    <Plus
                                                        color='#5E5E5E'
                                                        className='cursor-pointer'
                                                        onClick={() =>
                                                            handleDaysWorked(
                                                                'increment',
                                                                profile._id.toString() ?? profile?.id ?? ''
                                                            )
                                                        }
                                                        size={16}
                                                    />
                                                </button>
                                            </div>
                                        </td>
                                        <td className='text-center py-[7px]'>
                                            <div className='flex border border-[#DADCE0] max-w-fit  mx-auto h-[2rem] px-1.5 rounded-lg overflow-hidden bg-white'>
                                                <span className='flex items-center justify-center text-[#838383] my-2 ml-2 pr-2 border-r border-[#A8A8A8]'>
                                                    ₦
                                                </span>
                                                <span className='flex items-center w-full justify-center px-2'>
                                                    {formatAmountNoSymbol(profile.current_payout ?? profile.net_salary)}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className='py-[7px] pl-4 text-[13px]'>
                                        <div className='bg-offwhite px-4 py-2 w-fit text-gray7 font-semibold rounded'>
                                            1-{payrollList.length} of {payrollList.length} Employees
                                        </div>
                                    </td>
                                    <td></td>
                                    <td className='py-[7px] text-right pr-6'>
                                        <div className=''>
                                            <p className='font-semibold'>
                                                {formatAmount(
                                                    payrollList.reduce((acc, profile) => {
                                                        return acc + Number(profile.current_payout)
                                                    }, 0)
                                                )}
                                            </p>
                                            <p className='text-sm text-[#838383] font-semibold'>Total payout</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
                <div className='mt-4 flex pt-5 items-center justify-end gap-4 px-6 pb-3 py-2  absolute bottom-0 right-0 left-0 bg-white w-full rounded-b-lg'>
                    <Button
                        className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                        loading={isLoading}
                        onClick={() => {
                            mutate({
                                updates: payrollList.map(profile => ({
                                    payroll_id: profile._id ?? profile?.id,
                                    days_worked: profile.days_worked,
                                })),
                            })
                        }}
                    >
                        Save changes
                    </Button>
                </div>
            </main>
        </ModalWrapper>
    )
}
