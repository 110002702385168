import { useCurrencyContext } from '@/context'
import { useAddQueryParamsToURL } from '@/hooks/useAddQueryParamsToURL'
import { CurrencyNames } from '@/types/currency'

import { motion } from 'framer-motion'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../Select'
import { cn } from '@/lib/styling'

type Props = {
    hideAllFilter?: boolean
    currency?: string
    setCurrency: (id: string) => void
    triggerClassName?: string
}
const CurrencyFilter = () => {
    const { currencies, selectedCurrencyInfo } = useCurrencyContext()
    const initialCurrency = selectedCurrencyInfo()

    const [setCurrencyType, searchParamDetails] = useAddQueryParamsToURL<{ currency: keyof typeof CurrencyNames }>()
    const subRoute = searchParamDetails.get('currency') ?? initialCurrency.id
    return (
        <ul className='flex items-center gap-2 lg:gap-4  bg-white p-1 w-fit rounded-lg'>
            {currencies?.map(currency => {
                return (
                    <li
                        className={`relative ${currency?.id === subRoute ? 'text-brand ' : 'bg-transparent text-[#5E5E5E]'} text-xs rounded-md p-1.5 font-medium px-2 whitespace-nowrap`}
                        onClick={() => setCurrencyType('currency', currency?.id)}
                        key={currency?.id}
                    >
                        <button className='flex gap-2'>
                            {currency?.flag} - {currency?.currency_code} ({currency?.symbol})
                        </button>
                        {currency?.id === subRoute && (
                            <motion.div
                                layoutId='currency-filter'
                                className={'bg-brand/10 rounded  text-sm  w-full left-[1px] top-0  bottom-0 absolute'}
                            />
                        )}
                    </li>
                )
            })}
        </ul>
    )
}

export default CurrencyFilter

export const CurrencySelectFilter = ({ currency, setCurrency, triggerClassName, hideAllFilter = false }: Props) => {
    const { currencies } = useCurrencyContext()
    return (
        <Select name='currency' onValueChange={setCurrency} value={currency}>
            <SelectTrigger
                className={cn(
                    'w-fit gap-3 font-medium text-xs h-9 text-[#5E5E5E] bg-gray8/20  border-gray4/60',
                    triggerClassName
                )}
            >
                {<SelectValue className='' placeholder={'Currency - All'} />}
            </SelectTrigger>
            <SelectContent className='z-[100]'>
                {!hideAllFilter && (
                    <SelectItem className='text-xs' value={'all'}>
                        <span className='text-gray1'>Currency</span> - All
                    </SelectItem>
                )}
                {currencies?.map(curency => (
                    <SelectItem className='text-xs' value={curency?.id} key={curency?.id}>
                        {curency?.flag} - {curency?.name} ({curency?.symbol})
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    )
}
