import { Bar, BarChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { CustomBarTooltip2 as CustomBarTooltip } from '@/components/Tooltips/CustomTooltip'
import { OverAPeriodIcon } from '@/assets/assets.export'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { useEffect, useMemo, useState } from 'react'
import { formatAmountNoSymbol } from '@/utils/money'
import { useAuthContext, useCurrencyContext } from '@/context'
import { useGetSpecificMonthTransact } from '@/services/transactions/queries'
import {
    calculateAverageTotal,
    findMonthWithHighestTotal,
    getFullMonthName,
} from '@/modules/dashboard/Transactions/components/utils'
import { useSearchParams } from 'react-router-dom'

export default function TransactionsGraph() {
    const { selectedAccount } = useAuthContext()
    const [isSummaryYearClicked, setIsSummaryYearClicked] = useState(false)
    const { selectedCurrencyInfo } = useCurrencyContext()

    const [searchParams] = useSearchParams()
    const currency = searchParams.get('currency') as string

    const thisYear = new Date().getFullYear()
    const [transYear, setTransYear] = useState(thisYear + '')
    const { data, refetch, isLoading } = useGetSpecificMonthTransact({
        queryParams: {
            owner: selectedAccount?.org?.id as string,
            year: transYear as string,
        },
    })
    const chartData = useMemo(() => data ?? [], [data])
    const averageData = calculateAverageTotal(chartData)
    const highestMonth = findMonthWithHighestTotal(chartData)
    useEffect(() => {
        if (isSummaryYearClicked) {
            refetch()
        }
    }, [transYear])
    return (
        <div className={`bg-white rounded-2xl mb-4 ${isLoading ? '' : 'pb-8'}`}>
            <section className='p-2  md:p-4 lg:px-6 pt-6 pb-3'>
                <div className='flex items-center justify-between mb-6'>
                    <div className='ml-5'>
                        <span className='font-semibold'>Transaction graph</span>
                        {!(averageData.averageIncomingTotal == 0 && averageData.averageOutgoingTotal == 0) ? (
                            <div className='flex gap-3 items-center mt-2'>
                                <p>
                                    <span className='px-[10px] bg-brand mr-2 rounded'></span> Incoming
                                </p>
                                <p>
                                    <span className='px-[10px] bg-[#D5D5F5] mr-2 rounded'></span>Outgoing
                                </p>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div>
                        {!isLoading && (
                            <Select
                                name='invoiceYear'
                                onValueChange={data => {
                                    setTransYear(data)
                                    setIsSummaryYearClicked(true)
                                }}
                                value={transYear}
                            >
                                <SelectTrigger className='w-full'>
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent className='w-[8rem]'>
                                    <SelectItem value={`${thisYear - 1}`}>Last year</SelectItem>
                                    <SelectItem value={`${thisYear}`}>This year</SelectItem>
                                </SelectContent>
                            </Select>
                        )}
                    </div>
                </div>
                {isLoading ? (
                    <SkeletonTheme baseColor='transparent' highlightColor='#DADCE0' height={100}>
                        <Skeleton />
                    </SkeletonTheme>
                ) : (
                    <>
                        {/*==== Bar chart ====*/}
                        {!(averageData.averageIncomingTotal == 0 && averageData.averageOutgoingTotal == 0) ? (
                            <>
                                <div className={`bg-white p-1 md:p-4 rounded-md`}>
                                    <ResponsiveContainer height={350} width={'100%'} className={'mx-auto'}>
                                        <BarChart data={chartData} margin={{ left: 60 }}>
                                            <CartesianGrid
                                                stroke='#DADCE0'
                                                vertical={false}
                                                strokeDasharray='5 5'
                                                fill='white'
                                            />
                                            <XAxis
                                                dataKey='name'
                                                stroke='#888888'
                                                fontSize={12}
                                                tickLine={false}
                                                axisLine={false}
                                            />
                                            <YAxis
                                                // dataKey='total'
                                                stroke='#888888'
                                                fontSize={12}
                                                tickLine={false}
                                                axisLine={false}
                                                tickFormatter={value =>
                                                    `${selectedCurrencyInfo(currency)?.symbol}${formatAmountNoSymbol(value)}`
                                                }
                                                hide={false}
                                                label={{
                                                    value: 'Transactions in Naira',
                                                    angle: -90,
                                                    position: 'insideRight',
                                                    offset: 110,
                                                }}
                                                offset={10}
                                                // domain={[0, 'dataMax + 1']}
                                                type='number'
                                                // domain={[0, 'dataMax + 1']}
                                            />
                                            <Bar
                                                dataKey='incomingTotal'
                                                fill='#454ADE'
                                                radius={[4, 4, 0, 0]}
                                                barSize={20}
                                            />
                                            <Bar
                                                dataKey='outgoingTotal'
                                                fill='#D5D5F5'
                                                radius={[4, 4, 0, 0]}
                                                barSize={20}
                                            />
                                            <Tooltip
                                                filterNull={true}
                                                cursor={false}
                                                wrapperStyle={{ borderRadius: '15px', zIndex: '10' }}
                                                content={<CustomBarTooltip />}
                                            />
                                            <ReferenceLine y={0} stroke='#DADCE0' type='linear' />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className='flex flex-col md:flex-row md:ml-4 gap-4 text-sm md:text-base'>
                                    <div className='px-3 md:px-4 py-3 bg-offwhite md:w-[462px] rounded-lg '>
                                        In the month of{' '}
                                        <span className='font-semibold text-brand'>
                                            {highestMonth?.monthWithHighestOutgoingTotal &&
                                                getFullMonthName(highestMonth.monthWithHighestOutgoingTotal?.name)}
                                        </span>
                                        , {thisYear.toString() === transYear ? 'this' : 'last'} year, you had the
                                        highest outgoing amount of money (
                                        <span className='font-semibold'>
                                            {selectedCurrencyInfo(currency)?.symbol}
                                            {formatAmountNoSymbol(
                                                highestMonth.monthWithHighestOutgoingTotal?.outgoingTotal
                                            )}
                                        </span>
                                        ). While you had the highest incoming amount of money in the month of{' '}
                                        <span className='font-semibold text-brand'>
                                            {highestMonth?.monthWithHighestIncomingTotal &&
                                                getFullMonthName(highestMonth.monthWithHighestIncomingTotal?.name)}
                                        </span>{' '}
                                        (
                                        <span className='font-semibold'>
                                            {' '}
                                            {selectedCurrencyInfo(currency)?.symbol}
                                            {formatAmountNoSymbol(
                                                highestMonth.monthWithHighestIncomingTotal?.incomingTotal
                                            )}
                                        </span>
                                        ).
                                    </div>
                                    <div className='px-3 md:px-4 py-3 bg-offwhite md:w-[462px] rounded-lg'>
                                        Your average outgoing transaction amount was{' '}
                                        <span className='font-semibold text-brand'>
                                            {selectedCurrencyInfo(currency)?.symbol}
                                            {formatAmountNoSymbol(averageData.averageOutgoingTotal)}
                                        </span>
                                        . While your average incoming transaction amount was{' '}
                                        <span className='font-semibold text-brand'>
                                            {selectedCurrencyInfo(currency)?.symbol}
                                            {formatAmountNoSymbol(averageData.averageIncomingTotal)}
                                        </span>
                                        .
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='flex items-center justify-center flex-col text-gray7 h-full min-h-[320px]'>
                                    <OverAPeriodIcon />
                                    {/* <h2 className='text-gray7 font-medium mt-5'>No transactions</h2> */}
                                    <p className='text-sm mt-6'>No transactions here yet</p>
                                </div>
                            </>
                        )}
                    </>
                )}
            </section>
        </div>
    )
}
