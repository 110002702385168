import { MoveRight } from '@/assets/assets.export'
import { Input } from '@/components'
import { Select, SelectContent, SelectTrigger, SelectValue, SelectItem } from '@/components/Select'
import { Search } from 'lucide-react'

export default function CategoryMapping() {
    return (
        <section className='mb-5 bg-white rounded-xl p-7 flex-1'>
            <div>
                <h2 className='font-medium'>Category mapping</h2>
                <p className='text-sm text-gray7'>
                    Match Bizpend categories to your GL codes to automate category matching
                </p>
            </div>
            <table className='table-auto  border-collapse mt-8 '>
                <thead>
                    <tr>
                        <th className='font-medium text-sm text-left'>Bizpend categories </th>
                        <th className='font-medium text-sm w-28'></th>
                        <th className='font-medium text-sm text-left min-w-[15rem]'>Quickbook categories</th>
                    </tr>
                </thead>
                <tbody className=''>
                    <tr>
                        <td colSpan={3}>
                            <Input
                                className='border-gray4 h-10 my-4'
                                placeholder='Search category'
                                leftIcon={<Search className='text-gray' />}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className='inline-block bg-gray7/10 text-gray7 rounded p-1.5 px-2  text-xs font-medium '>
                                Travel cost{' '}
                            </span>
                        </td>
                        <td className='text-center'>
                            <MoveRight strokeWidth={1.5} color='#838383' className='mx-auto' />
                        </td>
                        <td>
                            <Select name='category'>
                                <SelectTrigger className='font-medium text-xs h-[2.5rem] border border-gray2/50 text-[#5E5E5E]'>
                                    <SelectValue className='' placeholder={'Select category'} />
                                </SelectTrigger>
                                <SelectContent className='w-[10rem]'>
                                    <SelectItem value='128'>128 - Marketing</SelectItem>
                                    <SelectItem value='194'>194 - Electricity</SelectItem>
                                    <SelectItem value='204'>204 - Office supplies</SelectItem>
                                </SelectContent>
                            </Select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    )
}
