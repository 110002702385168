import StatusColor from '../utils/StatusColor'
import { format } from 'date-fns'
import { TimelineEventType, TimelineIcon } from '@/utils/timeline'

type Props = {
    timelines: TimelineEventType[]
}
const TimelineJourney = ({ timelines }: Props) => {
    return <section className=''>{timelines?.map(timeline => <SingleTimeline singleTimeLine={timeline} />)}</section>
}

export default TimelineJourney

const SingleTimeline = ({ singleTimeLine }: { singleTimeLine: TimelineEventType }) => {
    const Icon = TimelineIcon[singleTimeLine.event_type]
    const timelineDetails = [
        {
            name: 'Description',
            desc: singleTimeLine?.description,
        },
        {
            name: 'Date',
            desc: singleTimeLine?.date ? format(new Date(singleTimeLine?.date), 'dd/MM/yyyy') : 'N/A',
            hidden: !singleTimeLine?.date,
        },
        {
            name: 'Time',
            desc: singleTimeLine?.date ? format(new Date(singleTimeLine?.date), 'hh:mm a') : 'N/A',
            hidden: !singleTimeLine?.date,
        },
    ]
    return (
        <div className='flex gap-3 relative pb-10 last:pb-0 group'>
            <div className='absolute h-full flex flex-col items-center justify-start'>
                <span className='bg-[#D5D5F5] inline-block rounded p-1.5'>
                    {Icon ? (
                        <Icon strokeWidth={1.5} className='w-5 h-5 text-black/90' />
                    ) : (
                        <span className='w-6 h-4 inline-block aspect-square' />
                    )}
                </span>
                <span className='w-[1px] bg-gray4 h-full group-last:hidden' />
            </div>
            <div className='space-y-2.5 ml-14 mt-0.5'>
                <StatusColor status={singleTimeLine.event_type?.toLowerCase()} className='text-[0.8rem] px-0' />
                <ul className='flex gap-2 lg:gap-12 flex-col lg:flex-row'>
                    {timelineDetails.map(timeline => {
                        if (timeline?.hidden) return
                        return (
                            <li>
                                <h5 className='text-[0.8rem] text-gray7/80'>{timeline.name}</h5>
                                <p
                                    className={`text-sm font-medium  ${timeline?.name === 'Description' ? 'w-60' : 'w-fit'}`}
                                >
                                    {timeline.desc}
                                </p>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}
