import { CancelIcon } from '@/assets/assets.export'
import { Button } from '@/components/Button'
import useSingleFileUpload, { fileEmptyState, FileUploadFormatProps } from '@/hooks/useSingleFileUpload'
import { useRef, useState } from 'react'

import { useUploadGlCode } from '@/services/accounting/mutations'

type Props = {
    closeModal: () => void
    nextStep?: () => void
    prevStep?: () => void
}

const ImportGLCodes = ({ closeModal, nextStep, prevStep }: Props) => {
    const [glCodeFile, setGlCodeFile] = useState<FileUploadFormatProps | undefined>(fileEmptyState)
    const glCodeRef = useRef<HTMLInputElement>(null)

    const { handleFile: uploadProofFn } = useSingleFileUpload({
        fileUpload: glCodeFile,
        setFileUpload: setGlCodeFile,
    })

    const { mutate: uploadGlCodeFn, isLoading } = useUploadGlCode({
        onSuccess: () => {
            nextStep?.()
        },
    })

    return (
        <section className='min-h-[15rem] w-[30rem] overflow-scroll '>
            <div className='flex items-center border-b border-[#DADCE0] p-2 px-4 justify-between'>
                <h2 className='text-center text-base font-medium opacity-80'>Import Chart of Accounts</h2>
                <CancelIcon className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer' onClick={() => closeModal()} />
            </div>
            <div className='p-4'>
                <h3 className='text-sm mb-1 font-medium'>Start with our standard template</h3>
                <p className='text-gray7 text-sm mt-1'>
                    Download the file below to ensure your Chart of Accounts matches the required format. Once you've
                    filled it out, upload it to proceed.
                </p>
                <h4 className='text-sm border-b border-gray5 pb-2 font-medium mt-6'>Instructions</h4>
                <ul className='text-gray7 list-decimal list-inside space-y-1 mt-3 text-sm'>
                    <li>
                        Download csv template{' '}
                        <a
                            className='font-medium text-brand'
                            href='/gl-sample.csv'
                            download='Gl-sample.csv'
                            target='_blank'
                            rel='noreferrer'
                        >
                            here
                        </a>
                    </li>

                    <li>Open the template and fill in the Chart of Accounts.</li>
                    <li>Upload the completed file once you’ve filled in the template.</li>
                </ul>

                <label
                    htmlFor={'gl-code'}
                    className='cursor-pointer upload flex items-center justify-center border border-dashed rounded-lg border-[#DADCE0] h-[5rem] mt-5'
                >
                    <span className='text-center text-base text-[#A8A8A8] font-medium my-auto'>
                        {glCodeFile?.file_name && glCodeFile?.file_name?.length > 0
                            ? glCodeFile?.file_name
                            : 'Click here to upload completed file'}
                        <br />
                        {glCodeFile?.file_name && glCodeFile?.file_name?.length > 0 ? (
                            <span className='text-sm'>
                                {glCodeFile?.size} |{' '}
                                <button
                                    className='font-semibold text-xs text-brand'
                                    onClick={() => glCodeRef?.current?.click()}
                                >
                                    Change document
                                </button>
                            </span>
                        ) : (
                            <span className='text-xs'>{'Max 2MB | Accepts .xls and .xlsx file only'}</span>
                        )}
                    </span>
                    <input
                        type='file'
                        id={'gl-code'}
                        ref={glCodeRef}
                        onChange={e => {
                            uploadProofFn(e)
                        }}
                        accept='.xls, .xlsx, .csv'
                        hidden
                    />
                </label>
            </div>

            <div className='border-t border-gray4 flex mt-6 justify-end items-center p-4 gap-4'>
                <Button onClick={prevStep} variant={'outline'} size={'lg'} className='text-xs min-w-[100px] '>
                    back
                </Button>
                <Button
                    onClick={() => {
                        const formdata = new FormData()
                        formdata.append('file', (glCodeFile?.fileBeforeFormData as Blob) ?? '')
                        uploadGlCodeFn(formdata)
                    }}
                    loading={isLoading}
                    disabled={glCodeFile && glCodeFile?.file_name?.length < 1}
                    size={'lg'}
                    className='text-xs px-4'
                >
                    Import Chart of Accounts
                </Button>
            </div>
        </section>
    )
}

export default ImportGLCodes
