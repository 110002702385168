import ModalWrapper from '../../ModalWrapper'
import { modalVariants } from '../../Expense/utils'
import { ApprovalPolicyArrow, ApprovalPolicyCondition, ApprovalPolicyHierarchy } from '@/assets/assets.export'
import { Button, Input } from '@/components'
import ApprovalPolicyBox, { ApproversSelectBox } from '@/components/ApprovalPolicyBox/ApprovalPolicyBox'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useMutationCreateApprovalPolicy, useMutationUpdateApprovalPolicy } from '@/services/settings/mutations'
import { ExpenseApprovalPolicy } from '@/services/settings/types'
import { ApporvalConditionOperatorEnum, ApprovalPolicyConsts, SupportedPolicyConsts } from '@/types/approval-policy'
import { EmployeeType, RoleEnum } from '@/types/org'
import { PlusSquare, X } from 'lucide-react'
import { useMemo } from 'react'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { AiOutlineClose } from 'react-icons/ai'
import { capitalizeText } from '@/utils/string'
import ApprovalPolicyNumberBox from '@/components/ApprovalPolicyBox/ApprovalPolicyNumberBox'

type FormType = {
    policy: {
        initialAmt?: string
        finalAmt?: string
        comparison?: string
        admins: EmployeeType[] | string[]
    }[]
    policy_title: string
    defaultPolicy: EmployeeType[] | string[]
}
type Props = {
    closeModal: () => void
    edit?: boolean
    updatePolicy?: ExpenseApprovalPolicy
    module: SupportedPolicyConsts
    name?: string
}

export default function CreatePolicyModal({ closeModal, edit, updatePolicy, module, name }: Props) {
    const deactivatedUsers = updatePolicy?.approval_levels
        ?.map(approvers => approvers?.approvers?.filter(approver => approver?.status === 'DEACTIVATED'))
        ?.filter(arr => arr?.length > 0)
        ?.flat() as EmployeeType[]

    const { selectedAccount } = useAuthContext()
    const { data: org_members } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })
    const orgMembers = useMemo(
        () =>
            org_members
                ?.filter(member => member?.user?.first_name || member?.user?.last_name)
                ?.filter(members =>
                    [RoleEnum.ADMIN, RoleEnum.OWNER, RoleEnum.FINANCE_ADMIN].includes(members?.role.name)
                ) ?? [],
        [org_members]
    )

    const methods = useForm<FormType>({
        defaultValues: {
            policy: updatePolicy
                ? updatePolicy?.approval_levels?.map(approvers => {
                      const policyDetails = {
                          initialAmt:
                              module !== SupportedPolicyConsts.leave //leave doesnt need the kobo conversion
                                  ? approvers?.operator === ApporvalConditionOperatorEnum.BETWEEN
                                      ? approvers?.min_amount.toString()
                                      : approvers?.amount.toString()
                                  : approvers?.operator === ApporvalConditionOperatorEnum.BETWEEN
                                    ? Number(Number(approvers?.min_amount) / 100).toString()
                                    : Number(Number(approvers?.amount) / 100).toString(),
                          finalAmt:
                              module !== SupportedPolicyConsts.leave
                                  ? approvers?.operator === ApporvalConditionOperatorEnum.BETWEEN
                                      ? approvers?.max_amount.toString()
                                      : undefined
                                  : approvers?.operator === ApporvalConditionOperatorEnum.BETWEEN
                                    ? Number(Number(approvers?.max_amount) / 100).toString()
                                    : undefined,
                          comparison: approvers?.operator,
                      }

                      return approvers?.auto_approval
                          ? {
                                admins: [ApprovalPolicyConsts.AUTOAPPROVE],
                                ...policyDetails,
                            }
                          : {
                                admins: approvers?.approvers,
                                ...policyDetails,
                            }
                  })
                : [{ comparison: ApporvalConditionOperatorEnum.LESS_THAN }],
            defaultPolicy: updatePolicy
                ? updatePolicy?.fallback_approval_level?.auto_approval
                    ? [ApprovalPolicyConsts.AUTOAPPROVE]
                    : updatePolicy?.fallback_approval_level?.approvers
                : [],
            // : orgMembers.filter(members => [RoleEnum.OWNER].includes(members?.role?.name)),
            policy_title: updatePolicy?.policy_name,
        },
    })
    const { fields, append, remove } = useFieldArray<FormType>({
        control: methods.control,
        name: 'policy',
    })

    const { mutate: createPolicyFn, isLoading } = useMutationCreateApprovalPolicy({
        onSuccess: () => {
            toastHandler({ message: `${capitalizeText(module)} Policy created successfully`, state: 'success' })
            closeModal()
        },
    })
    const { mutate: updateApprovalPolicyFn, isLoading: isApprovalPolicyUpdated } = useMutationUpdateApprovalPolicy({
        queryParams: {
            policy_id: updatePolicy?._id ?? (updatePolicy?.id as string),
        },
        onSuccess: () => {
            toastHandler({ message: `${capitalizeText(module)} Policy updated successfully`, state: 'success' })
            closeModal()
        },
    })

    const onSubmit = (value: FormType) => {
        const approvalLevels = value?.policy?.map(policy => {
            const isPolicyAutoApprove = typeof policy.admins?.[0] === 'string' //simply means i have auto approve in the array
            if (policy.comparison === ApporvalConditionOperatorEnum.BETWEEN) {
                return {
                    min_amount:
                        module !== SupportedPolicyConsts.leave
                            ? Number(policy.initialAmt) / 100
                            : Number(policy.initialAmt),
                    max_amount:
                        module !== SupportedPolicyConsts.leave
                            ? Number(policy.finalAmt) / 100
                            : Number(policy.finalAmt),
                    operator: ApporvalConditionOperatorEnum.BETWEEN,
                    approvers: isPolicyAutoApprove
                        ? []
                        : policy?.admins?.map(admin => (admin as EmployeeType)?.id ?? (admin as EmployeeType)?._id),
                    auto_approval: isPolicyAutoApprove ? true : false,
                }
            }
            return {
                amount:
                    module !== SupportedPolicyConsts.leave
                        ? Number(policy.initialAmt) / 100
                        : Number(policy.initialAmt),
                operator: policy?.comparison,
                approvers: isPolicyAutoApprove
                    ? []
                    : policy?.admins?.map(admin => (admin as EmployeeType)?.id ?? (admin as EmployeeType)?._id),
                auto_approval: isPolicyAutoApprove ? true : false,
            }
        })
        const data_to_server = {
            policy_name: value.policy_title,
            org: selectedAccount?.org?.id,
            module: module,
            approval_levels: approvalLevels,
            fallback_approval_level: {
                approvers:
                    typeof value?.defaultPolicy?.[0] === 'string'
                        ? []
                        : value?.defaultPolicy?.map(
                              admin => (admin as EmployeeType)?.id ?? (admin as EmployeeType)?._id
                          ),
                auto_approval: typeof value?.defaultPolicy?.[0] === 'string' ? true : false,
            },
        }

        if (!edit) {
            createPolicyFn(data_to_server)
            return
        }
        updateApprovalPolicyFn({ policy: data_to_server })
    }
    return (
        <ModalWrapper
            closeModal={() => closeModal()}
            className='justify-end right-8'
            variants={modalVariants}
            modalClassName='rounded-none bg-[red]'
            formId='billPolicy'
            shouldConfirmClose
        >
            <div className='h-[100vh] w-screen lg:w-[43rem]  relative  overflow-hidden overflow-y-scroll mb[20rem]'>
                <div className='sticky top-0 w-full bg-white z-10 border-b border-[#DADCE0] py-4 px-2 flex'>
                    <div className='flex items-center w-full'>
                        <h2 className='font-semibold text-[#31254B] text-lg flex-1 text-center'>
                            {name ? capitalizeText(name) : edit ? 'Edit' : 'Create'}{' '}
                            {name ? '' : `${capitalizeText(module)} Policy`}
                        </h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => closeModal()}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <section className=' bg-white mt-5 h-[85%]'>
                    <FormProvider {...methods}>
                        <form id='billPolicy' onSubmit={methods.handleSubmit(onSubmit)} className='h-full'>
                            <div className='space-y-4 overflow-y-scroll pl-3 lg:pl-5 p-5  h-full'>
                                <fieldset className='mb-[15px] flex flex-col flex-1  gap-2'>
                                    <label className='text-base opacity-80 font-semibold' htmlFor='policy_title'>
                                        Policy title
                                    </label>
                                    <Controller
                                        name='policy_title'
                                        rules={{
                                            required: true,
                                            minLength: 2,
                                        }}
                                        control={methods.control}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                placeholder='Enter policy title'
                                                className='border-[#DADCE0] h-[3rem] placeholder:text-xs max-w-sm'
                                            />
                                        )}
                                    />
                                </fieldset>
                                {fields.map((field, idx) => (
                                    <div className='relative max-w-3xl' key={field.id}>
                                        <button
                                            aria-label='Icon'
                                            type='button'
                                            onClick={() => remove(idx)}
                                            className='backdrop-blur-lg -top-2 -right-2 absolute text-errorRed bg-errorRed/10 rounded-full p-1'
                                        >
                                            <X className=' w-5 h-5 ' />
                                        </button>

                                        {module === SupportedPolicyConsts.leave && (
                                            <ApprovalPolicyNumberBox
                                                deactivatedUsers={deactivatedUsers}
                                                orgMembers={orgMembers}
                                                index={idx}
                                            />
                                        )}
                                        {module !== SupportedPolicyConsts.leave && (
                                            <ApprovalPolicyBox
                                                deactivatedUsers={deactivatedUsers}
                                                orgMembers={orgMembers}
                                                index={idx}
                                            />
                                        )}
                                    </div>
                                ))}

                                <div>
                                    <div className='flex items-center gap-4'>
                                        <ApprovalPolicyCondition className='opacity-50' />

                                        <button
                                            type='button'
                                            onClick={() =>
                                                append({
                                                    admins: [],
                                                    comparison: ApporvalConditionOperatorEnum.LESS_THAN,
                                                })
                                            }
                                            className='flex gap-2 bg-brand/10 text-brand text-xs font-medium p-2 px-3 items-center rounded-md'
                                        >
                                            <PlusSquare className='text-brand rounded-md overflow-hidden' />
                                            Add condition
                                        </button>
                                    </div>
                                </div>

                                <div className='!mb-14 !mt-6 ml-1'>
                                    <span className='w-fit flex gap-2 bg-green/10 border border-green text-green text-sm font-medium p-1.5 px-3 items-center rounded-md mb-2'>
                                        <ApprovalPolicyHierarchy className='text-green rounded-md overflow-hidden' />
                                        If no condition is met
                                    </span>
                                    <div className='flex items-end gap-3 '>
                                        <ApprovalPolicyArrow className='ml-1.5' />

                                        <div className='flex items-center gap-3 relative -bottom-5'>
                                            <span className='text-errorRed font-medium text-sm'>Require</span>

                                            <ApproversSelectBox
                                                deactivatedUsers={deactivatedUsers}
                                                orgMembers={orgMembers}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='border-t pt-4 border-gray5 fixed bottom-5 px-4 pb-2  w-full z-10 bg-white'>
                                <Button
                                    loading={isLoading || isApprovalPolicyUpdated}
                                    type='submit'
                                    className='ml-auto'
                                    disabled={!methods.formState.isValid}
                                >
                                    {edit ? 'Save' : 'Create'} policy
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                </section>
            </div>
        </ModalWrapper>
    )
}
