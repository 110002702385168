import { Button, Input } from '@/components'
import { useResetPassword } from '@/services/auth/mutations'
import { CheckIcon, EyeIcon, EyeOffIcon } from 'lucide-react'
import { useState } from 'react'
import { Controller, Control, FieldValues, UseFormGetValues, UseFormWatch } from 'react-hook-form'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

interface Step3Props {
    control: Control<FieldValues, any>
    values: UseFormGetValues<FieldValues>
    watch: UseFormWatch<FieldValues>
    toggleStep: (step: number) => void
}

const Password_regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/

const ResetStep3 = ({ control, values, watch, toggleStep }: Step3Props) => {
    const watchPassword = watch('password')
    const watchConfirmPassword = watch('confirm-password')

    const navigate = useNavigate()

    const { mutate: createNewPasswordFn, isLoading } = useResetPassword({
        onSuccess: () => {
            navigate('/login')
        },
    })

    const isPasswordValid = watchPassword === watchConfirmPassword && Password_regex.test(watchPassword)
    const handleNewPassword = () => {
        createNewPasswordFn({
            email: values()?.email,
            token: values()?.otp,
            password: values()?.password,
        })
    }
    const [showPassword1, setShowPassword1] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)
    const handleShowPassword1 = () => {
        setShowPassword1(prev => !prev)
    }
    const handleShowPassword2 = () => {
        setShowPassword2(prev => !prev)
    }

    const checkIfUpperCase =
        watchPassword === undefined || watchPassword.length < 1 ? false : /[A-Z]|[\u0080-\u024F]/.test(watchPassword)
    const checkIfLowerCase =
        watchPassword === undefined || watchPassword.length < 1 ? false : /[a-z]|[\u0080-\u024F]/.test(watchPassword)
    const checkIfHasNumber =
        watchPassword === undefined || watchPassword.length < 1 ? false : /[0123456789]/.test(watchPassword)
    // const checkIfHasSpecialChar =
    //     watchPassword === undefined || watchPassword.length < 1 ? false : /[~`!@#$%^&*()_+='"?.,]/.test(watchPassword)
    const checkIfPassWordEqual =
        watchPassword?.length > 0 && watchConfirmPassword?.length > 0 ? watchPassword === watchConfirmPassword : false

    return (
        <div className='w-[90%] md:w-[45%] max-w-[1100px] mx-auto my-12'>
            <div className='flex justify-end mb-8'>
                <span className='py-2 px-4 bg-[#EFEFF9] rounded-2xl flex items-center gap-2'>
                    3 / 3 <span className='font-semibold'> steps</span>
                </span>
            </div>

            <section className='pt-4'>
                <h2 className='text-[#31254B] text-2xl mb-2 font-semibold'>Create new password</h2>
                <p className='text-[#202020] mb-6 text-sm'>Create and confirm your new password</p>

                <div className='flex flex-col md:flex-row mb-4'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='email' className='text-[#202020] text-[14px]'>
                            Password
                        </label>
                        <Controller
                            name='password'
                            rules={{
                                required: true,
                                minLength: 6,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    type={showPassword1 ? 'text' : 'password'}
                                    placeholder='Enter your password'
                                    className='h-16'
                                    rightIcon={
                                        showPassword1 ? (
                                            <EyeIcon onClick={handleShowPassword1} className='cursor-pointer' />
                                        ) : (
                                            <EyeOffIcon onClick={handleShowPassword1} className='cursor-pointer' />
                                        )
                                    }
                                />
                            )}
                        />
                    </div>
                </div>

                <div className='flex flex-col md:flex-row my-8'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='email' className='text-[#202020] text-[14px]'>
                            Confirm password
                        </label>
                        <Controller
                            name='confirm-password'
                            rules={{
                                required: true,
                                minLength: 6,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    type={showPassword2 ? 'text' : 'password'}
                                    placeholder='confirm password'
                                    className='h-16'
                                    rightIcon={
                                        showPassword2 ? (
                                            <EyeIcon onClick={handleShowPassword2} className='cursor-pointer' />
                                        ) : (
                                            <EyeOffIcon onClick={handleShowPassword2} className='cursor-pointer' />
                                        )
                                    }
                                />
                            )}
                        />
                    </div>
                </div>
                <ul className='text-[14px] text-[#838383] flex flex-col gap-2'>
                    <li className='flex gap-2 items-center font-medium'>
                        <CheckIcon
                            className={`rounded-full border-2 p-1 scale-75 ${
                                watchPassword?.length > 7 ? 'text-[#19C37D]' : 'text-[#838383]'
                            }`}
                            strokeWidth={4}
                        />
                        <span className={`${watchPassword?.length > 7 ? 'text-[#202020]' : 'text-[#838383]'}`}>
                            Your password must be a minimum of 8 characters long.
                        </span>
                    </li>
                    <li className='flex gap-2 items-center font-medium'>
                        <CheckIcon
                            className={`rounded-full border-2 p-1 scale-75 ${
                                checkIfUpperCase ? 'text-[#19C37D]' : 'text-[#838383]'
                            }`}
                            strokeWidth={4}
                        />
                        <span className={`${checkIfUpperCase ? 'text-[#202020]' : 'text-[#838383]'}`}>
                            Your password must contain at least one uppercase letter (A-Z).
                        </span>
                    </li>
                    <li className='flex gap-2 items-center font-medium'>
                        <CheckIcon
                            className={`rounded-full border-2 p-1 scale-75 ${
                                checkIfLowerCase ? 'text-[#19C37D]' : 'text-[#838383]'
                            }`}
                            strokeWidth={4}
                        />
                        <span className={`${checkIfLowerCase ? 'text-[#202020]' : 'text-[#838383]'}`}>
                            Your password must contain at least one lowercase letter (a-z)
                        </span>
                    </li>
                    <li className='flex gap-2 items-center font-medium'>
                        <CheckIcon
                            className={`rounded-full border-2 p-1 scale-75 ${
                                checkIfHasNumber ? 'text-[#19C37D]' : 'text-[#838383]'
                            }`}
                            strokeWidth={4}
                        />
                        <span className={`${checkIfHasNumber ? 'text-[#202020]' : 'text-[#838383]'}`}>
                            Your password must contain at least one numeric character (0-9)
                        </span>
                    </li>
                    {/* <li className='flex gap-2 items-center font-medium'>
                        <CheckIcon
                            className={`rounded-full border-2 p-1 scale-75 ${
                                checkIfHasSpecialChar ? 'text-[#19C37D]' : 'text-[#838383]'
                            }`}
                            strokeWidth={4}
                        />
                        <span className={`${checkIfHasSpecialChar ? 'text-[#202020]' : 'text-[#838383]'}`}>

                        </span>
                    </li> */}
                    <li className='flex gap-2 items-center font-medium'>
                        <CheckIcon
                            className={`rounded-full border-2 p-1 scale-75 ${
                                checkIfPassWordEqual ? 'text-[#19C37D]' : 'text-[#838383]'
                            }`}
                            strokeWidth={4}
                        />
                        <span className={`${checkIfPassWordEqual ? 'text-[#202020]' : 'text-[#838383]'}`}>
                            New password must match
                        </span>
                    </li>
                </ul>

                <div className='mt-[110px] flex justify-end gap-6'>
                    <button type='button' onClick={() => toggleStep(2)} className='flex items-center gap-3 text-[14px]'>
                        <AiOutlineArrowLeft fontSize={15} className='mt-1' />
                        go back
                    </button>

                    <Button
                        disabled={!isPasswordValid}
                        variant='default'
                        onClick={handleNewPassword}
                        loading={isLoading}
                    >
                        Create new password
                    </Button>
                </div>
            </section>
        </div>
    )
}

export default ResetStep3
