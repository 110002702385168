import { ApprovalPolicyCondition, ApprovalPolicyArrow } from '@/assets/assets.export'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../Select'
import { Input } from '../Input'
import { Controller, useFormContext } from 'react-hook-form'
import { ApporvalConditionOperatorEnum } from '@/types/approval-policy'
import { RadioGroup, RadioGroupItem } from '../Radio Group'
import { formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import { TbCurrencyNaira } from 'react-icons/tb'

type Props = {
    index?: number
}

export default function AccountingApprovalPolicyBox({ index }: Props) {
    const { control, watch } = useFormContext()
    const watchComparison = watch(`policy.${index}.comparison`)

    return (
        <section className='flex gap-3 w-full  items-start'>
            <div className=''>
                <div className='flex flex-col items-center h-full'>
                    <button title='Icon' type='button'>
                        <ApprovalPolicyCondition />
                    </button>
                    <div className='w-[1px] bg-gray5  h-[14rem] mt-2'></div>
                </div>
            </div>
            <div className='flex gap-4 border border-gray5 rounded-2xl px-2 p-5 lg:px-5 w-full  '>
                <h5 className='text-brand font-semibold text-lg  relative -top-0.5'>IF</h5>
                <div className='flex flex-col gap-1'>
                    <div className='flex items-center gap-2'>
                        <span className='bg-brand/10 text-brand text-xs p-1 px-2 rounded-full'>Transaction</span>
                    </div>

                    <div className='w-[1px] bg-gray5 h-10 ml-1.5 '></div>

                    <div className='flex lg:items-center gap-3 flex-col lg:flex-row'>
                        <div className='flex items-center gap-3'>
                            <span className='text-sm whitespace-nowrap'>Number is:</span>
                            <Controller
                                name={`policy.${index}.comparison`}
                                rules={{
                                    required: true,
                                    minLength: 2,
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        name='type'
                                        defaultValue={ApporvalConditionOperatorEnum.BETWEEN}
                                        onValueChange={data => field.onChange(data)}
                                    >
                                        <SelectTrigger className=' border-none text-sm h-[2.3rem] w-fit bg-[#F9F9F9] text-[#5E5E5E] whitespace-nowrap'>
                                            <SelectValue placeholder={''} />
                                        </SelectTrigger>
                                        <SelectContent className='w-[10rem]'>
                                            <SelectItem value={ApporvalConditionOperatorEnum.BETWEEN}>
                                                Between
                                            </SelectItem>
                                            <SelectItem value={ApporvalConditionOperatorEnum.EQUAL}>Equal</SelectItem>
                                            <SelectItem value={ApporvalConditionOperatorEnum.GREATER_THAN}>
                                                Greater than
                                            </SelectItem>
                                            <SelectItem value={ApporvalConditionOperatorEnum.LESS_THAN}>
                                                Less than
                                            </SelectItem>
                                            <SelectItem value={ApporvalConditionOperatorEnum.NOT_EQUAL}>
                                                Not equal
                                            </SelectItem>
                                        </SelectContent>
                                    </Select>
                                )}
                            />
                        </div>

                        <Controller
                            name={`policy.${index}.initialAmt`}
                            rules={{
                                required: true,
                                minLength: 0,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    min={0}
                                    value={formatAmount(field.value).replaceAll('₦', '')}
                                    onChange={e => {
                                        const value_entered = formatAmountWithoutSymbols(e.target.value)
                                        field.onChange(value_entered)
                                    }}
                                    bodyClassName='xl:min-w-[120px] max-w-[150px] lg:ml-3 lg:mb-0'
                                    className='h-[2.5rem] border-gray4/60  px-2'
                                    leftIcon={<TbCurrencyNaira fontSize={25} className='text-gray7' />}
                                />
                            )}
                        />

                        {watchComparison === ApporvalConditionOperatorEnum.BETWEEN && (
                            <div className='flex gap-4 items-center'>
                                <span className='text-gray7 p-1 px-2 bg-gray6 rounded-lg h-fit text-sm'>and</span>
                                <Controller
                                    name={`policy.${index}.finalAmt`}
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            min={0}
                                            value={formatAmount(field.value).replaceAll('₦', '')}
                                            onChange={e => {
                                                const value_entered = formatAmountWithoutSymbols(e.target.value)
                                                field.onChange(value_entered)
                                            }}
                                            bodyClassName='xl:min-w-[120px] max-w-[150px]'
                                            className='h-[2.5rem] border-gray4/60  px-2'
                                            leftIcon={<TbCurrencyNaira fontSize={25} className='text-gray7' />}
                                        />
                                    )}
                                />
                            </div>
                        )}
                    </div>
                    <div className='flex items-end gap-3 mb-5'>
                        <ApprovalPolicyArrow className='ml-1.5' />

                        <div className='flex lg:items-center gap-3 relative -bottom-1.5 flex-col lg:flex-row'>
                            <span className='text-errorRed font-medium text-sm border-r border-gray/50 pr-5 '>
                                Require receipt
                            </span>
                            <Controller
                                name='receipt'
                                rules={{
                                    required: true,
                                    minLength: 2,
                                }}
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        onValueChange={field.onChange}
                                        className='grid grid-cols-1 lg:grid-cols-3 gap-6'
                                    >
                                        <label htmlFor={'yes'} className={`flex gap-2 items-center`}>
                                            <RadioGroupItem type='button' value={'yes'} id={'yes'} />
                                            Yes
                                        </label>
                                        <label htmlFor={'no'} className={`flex gap-2 items-center`}>
                                            <RadioGroupItem type='button' value={'no'} id={'no'} />
                                            No
                                        </label>
                                    </RadioGroup>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
