import { MoveRight, ProfileEditIcon } from '@/assets/assets.export'
import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import DeeleteUploadedCOAPrompt from '@/components/Modals/Accounting/DeleteUploadedCOAPrompt'
import { useAccountingContext } from '@/context'
import useSingleFileUpload, { fileEmptyState, FileUploadFormatProps } from '@/hooks/useSingleFileUpload'
import { useUploadGlCode } from '@/services/accounting/mutations'
import { useGetGLCode } from '@/services/accounting/queries'
import { OrgGlCodeType } from '@/types/gl-code'
import { PlusSquare, Trash2 } from 'lucide-react'
import { useMemo, useRef, useState } from 'react'

type GlProps = {
    changeStep: () => void
}
export default function ManageGLCode() {
    const { setHasGlCode, setOrgGlCode, orgGlCode, hasGlCode } = useAccountingContext()

    const [step, setStep] = useState(hasGlCode ? 2 : 1)

    const { data } = useGetGLCode({
        enabled: (orgGlCode?.codes?.length as number) < 1,
        onSuccess: data => {
            if (data?.codes?.length > 0) {
                setHasGlCode(true)
                setOrgGlCode(data)
            }
        },
    })
    const glCodes = useMemo(() => orgGlCode ?? data ?? ({} as OrgGlCodeType), [data, orgGlCode])

    return (
        <section className='mb-5  flex-1'>
            {step === 1 && <UploadGLCode changeStep={() => setStep(2)} />}
            {step === 2 && <HasExistingGLCode glCodes={glCodes} changeStep={() => setStep(1)} />}
        </section>
    )
}
const HasExistingGLCode = ({ changeStep, glCodes }: GlProps & { glCodes: OrgGlCodeType }) => {
    const [removeCOA, setRemoveCOA] = useState(false)

    console.table({ glCodes })
    return (
        <>
            <section className=' bg-white rounded-xl p-7'>
                <h2 className='font-medium text-md border-b border-gray5 pb-2 mb-5'>Integration / Imported file</h2>

                <div className='flex justify-between items-center p-4 border border-gray5 rounded-lg'>
                    <div>
                        <span className='text-sm font-medium block'>Kuda_MFB_Sage_GL codes.xls</span>
                        <span className='text-gray7 text-xs block font-medium mt-1'>240.77kb</span>
                    </div>
                    <div className='flex items-center gap-4'>
                        <button
                            className='flex flex-col items-center justify-center text-errorRed'
                            onClick={() => setRemoveCOA(true)}
                        >
                            <Trash2 width={17} strokeWidth={1.5} />
                            <span className='text-[.7rem] font-medium'>Delete</span>
                        </button>
                        <button className='flex flex-col items-center justify-center' onClick={() => changeStep()}>
                            <ProfileEditIcon width={17} strokeWidth={1.5} />
                            <span className='text-[.7rem] font-medium text-[#555555]'>Change</span>
                        </button>
                    </div>
                </div>

                <div className='mt-8'>
                    <h2 className='font-medium text-md border-b border-gray5 pb-2'>Chart of accounts details</h2>

                    <fieldset className='border border-gray5 rounded-lg p-4 mt-5 flex flex-col gap-2'>
                        <label htmlFor='account_name' className='text-sm text-gray7'>
                            Chart of accounts name
                        </label>
                        <Input
                            id='account_name'
                            placeholder='Enter chart of accounts name'
                            className='max-w-xs h-12 border-gray5'
                        />
                    </fieldset>
                </div>

                <div className='mt-12 flex gap-3 justify-end'>
                    <Button variant='outline' size={'lg'}>
                        Cancel
                    </Button>
                    <Button size={'lg'}>Save changes</Button>
                </div>
            </section>

            <section className='mt-5 bg-white rounded-xl p-7'>
                <div className='border-b border-gray5 pb-3'>
                    <h2 className='font-medium'>Chart of accounts table titles</h2>
                    <p className='text-sm text-gray7'>
                        Map default Bizpend titles to your accounting titles and create custom table titles
                    </p>
                </div>

                <div className='my-6 border border-gray5 rounded-lg p-4'>
                    <h4 className='font-medium mb-3'>Default Tiles</h4>
                    <ul className='grid grid-cols-4 mb-3 max-w-xl border-gray5 pb-3 border-b'>
                        <li className='font-medium text-base text-left whitespace-nowrap'>Bizpend categories </li>
                        <li className='font-medium text-base'></li>
                        <li className='font-medium text-base text-left min-w-[15rem] col-span-2'>Your Fields</li>
                    </ul>
                    <ul className='grid grid-cols-4 max-w-xl mt-5 gap-5'>
                        {['ID', 'Account name', 'Type', 'Description'].map(title => (
                            <>
                                <li>
                                    <span className='inline-block bg-gray7/10 text-gray7 rounded p-1.5 px-2  text-xs font-medium '>
                                        {title}
                                    </span>
                                </li>

                                <li>
                                    <MoveRight strokeWidth={1.5} color='#838383' className='mx-auto' />
                                </li>
                                <li className='col-span-2'>
                                    <Select name='category'>
                                        <SelectTrigger className='font-medium text-xs h-[2.5rem] border border-gray2/50 text-[#5E5E5E]'>
                                            <SelectValue className='' placeholder={'Select category'} />
                                        </SelectTrigger>
                                        <SelectContent className='w-[10rem]'>
                                            <SelectItem value='128'>128 - Marketing</SelectItem>
                                            <SelectItem value='194'>194 - Electricity</SelectItem>
                                            <SelectItem value='204'>204 - Office supplies</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </li>
                            </>
                        ))}
                    </ul>

                    <h4 className='font-medium mb-3 border-gray5 pt-3 border-t mt-8'>Custom Tiles</h4>
                    <ul className='grid grid-cols-4 mb-3 max-w-xl border-gray5 pb-3 border-b'>
                        <li className='font-medium text-base text-left whitespace-nowrap'>Bizpend categories </li>
                        <li className='font-medium text-base'></li>
                        <li className='font-medium text-base text-left min-w-[15rem] col-span-2'>Your Fields</li>
                    </ul>
                    <ul className='grid grid-cols-4 max-w-xl mt-5 gap-5 border-b border-gray5 pb-5'>
                        {['Sub-type', 'Location'].map(title => (
                            <>
                                <li>
                                    <span className='inline-block border border-gray7/30 text-gray7 rounded-lg p-1.5 px-2  text-xs font-medium '>
                                        {title}
                                    </span>
                                </li>

                                <li>
                                    <MoveRight strokeWidth={1.5} color='#838383' className='mx-auto' />
                                </li>
                                <li className='col-span-2'>
                                    <Select name='category'>
                                        <SelectTrigger className='font-medium text-xs h-[2.5rem] border border-gray2/50 text-[#5E5E5E]'>
                                            <SelectValue className='' placeholder={'Select category'} />
                                        </SelectTrigger>
                                        <SelectContent className='w-[10rem]'>
                                            <SelectItem value='128'>128 - Marketing</SelectItem>
                                            <SelectItem value='194'>194 - Electricity</SelectItem>
                                            <SelectItem value='204'>204 - Office supplies</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </li>
                            </>
                        ))}
                    </ul>
                    <div className='mt-5'>
                        <button
                            aria-label='map more accounts'
                            className='flex items-center gap-2 text-brand text-sm font-medium'
                        >
                            <PlusSquare className='w-4 h-4' />
                            Add custom field
                        </button>
                    </div>
                </div>

                <div className='mt-12 flex gap-3 justify-end border-t border-gray5 pt-6'>
                    <Button variant='outline' size={'lg'}>
                        Cancel
                    </Button>
                    <Button size={'lg'}>Save changes</Button>
                </div>
            </section>

            {removeCOA && <DeeleteUploadedCOAPrompt closeModal={() => setRemoveCOA(false)} />}
        </>
    )
}

const UploadGLCode = ({ changeStep }: GlProps) => {
    const [glCodeFile, setGlCodeFile] = useState<FileUploadFormatProps | undefined>(fileEmptyState)
    const glCodeRef = useRef<HTMLInputElement>(null)

    const { handleFile: uploadProofFn } = useSingleFileUpload({
        fileUpload: glCodeFile,
        setFileUpload: setGlCodeFile,
    })
    const { mutate: uploadGlCodeFn, isLoading } = useUploadGlCode({
        onSuccess: () => {
            changeStep?.()
        },
    })

    return (
        <section className='bg-white rounded-xl p-7'>
            <h3 className='mt-4'>Upload GL codes</h3>
            <p className='text-gray7 text-sm mt-1'>
                These codes will be used to map the categories in your accounting software, ensuring accurate and
                organized financial records
            </p>
            <h4 className='text-sm border-b border-gray5 pb-2 font-medium mt-6'>Instructions</h4>
            <ul className='text-gray7 list-decimal list-inside space-y-1 mt-3 text-sm'>
                <li>
                    Download csv template{' '}
                    <a
                        className='font-medium text-brand'
                        href='/gl-sample.csv'
                        download='Gl-sample.csv'
                        target='_blank'
                        rel='noreferrer'
                    >
                        here
                    </a>
                </li>
                <li>Fill in your GL codes: Open the template and fill in your GL codes.</li>
                <li>Upload the completed file once you’ve filled in the template.</li>
                <li>Click the “Import GL codes” button after uploading the file</li>
            </ul>
            <div className='bg-[#EFEFF9] rounded-lg p-4 mt-5 w-fit'>
                <h5 className='font-medium text-sm'>Note</h5>
                <p className='text-xs text-gray7'>
                    Ensure the GL codes match the categories in your accounting software (e.g., QuickBooks, Xero) for
                    smooth integration.
                </p>
            </div>

            <label
                htmlFor={'gl-code'}
                className='cursor-pointer upload flex items-center justify-center border border-dashed rounded-lg border-[#DADCE0] h-[7.6rem] mt-8'
            >
                <h1 className='text-center text-base text-[#A8A8A8] font-medium my-auto'>
                    {glCodeFile?.file_name && glCodeFile?.file_name?.length > 0
                        ? glCodeFile?.file_name
                        : ' Upload GL code'}
                    <br />
                    {glCodeFile?.file_name && glCodeFile?.file_name?.length > 0 ? (
                        <span className='text-sm'>
                            {glCodeFile?.size} |{' '}
                            <button
                                className='font-semibold text-xs text-brand'
                                onClick={() => glCodeRef?.current?.click()}
                            >
                                Change document
                            </button>
                        </span>
                    ) : (
                        <span className='text-sm'>{'Max 2MB | Accepts .xls and .xlsx file only'}</span>
                    )}
                </h1>
                <input
                    type='file'
                    id={'gl-code'}
                    ref={glCodeRef}
                    onChange={e => {
                        uploadProofFn(e)
                    }}
                    accept='.xls, .xlsx, .csv'
                    hidden
                />
            </label>

            <div className='flex justify-end mt-8'>
                <Button
                    onClick={() => {
                        const formdata = new FormData()
                        formdata.append('file', (glCodeFile?.fileBeforeFormData as Blob) ?? '')
                        uploadGlCodeFn(formdata)
                    }}
                    disabled={glCodeFile && glCodeFile?.file_name?.length < 1}
                    loading={isLoading}
                >
                    Import GL codes
                </Button>
            </div>
        </section>
    )
}
