import ModalWrapper from '../ModalWrapper'
import { useState } from 'react'
import AccountingSetupSuccessModal from './AccountingSetupSuccessModal'
import { FormProvider, useForm } from 'react-hook-form'

import AccountingMapToBizpend from './AccountingMapToBizpend'
import AccountingPlatform from './AccountingPlatform'
import ImportGLCodes from './ImportGLCodes'
import AccountingIntegrationSuccess from './AccountingIntegrationSuccess'
import ManageUploadedGLCodes from './ManageUploadedGLCodes'

const accountingConsts = {
    manual: 'manual',
}

type Props = {
    closeModal: () => void
    nextStep?: () => void
    prevStep?: () => void
}

export type AccountingSetUpFormData = {
    platform: string
}
export default function AccountingSetupModal({ closeModal }: Props) {
    const [steps, setSteps] = useState(1)
    const form = useForm<AccountingSetUpFormData>()

    const platform = form.watch('platform')

    return (
        <ModalWrapper>
            <FormProvider {...form}>
                {steps === 1 && <AccountingPlatform nextStep={() => setSteps(2)} closeModal={closeModal} />}
                {steps === 2 && platform === accountingConsts.manual && (
                    <ImportGLCodes prevStep={() => setSteps(1)} nextStep={() => setSteps(3)} closeModal={closeModal} />
                )}
                {steps === 2 && platform !== accountingConsts.manual && (
                    <AccountingIntegrationSuccess
                        nextStep={() => setSteps(3)}
                        closeModal={closeModal}
                        platform={platform}
                    />
                )}
                {steps === 3 && platform !== accountingConsts.manual && (
                    <AccountingMapToBizpend closeModal={closeModal} />
                )}

                {steps === 3 && platform === accountingConsts.manual && (
                    <ManageUploadedGLCodes
                        prevStep={() => setSteps(2)}
                        nextStep={() => setSteps(4)}
                        closeModal={closeModal}
                    />
                )}
                {steps === 4 && platform === accountingConsts.manual && (
                    <AccountingSetupSuccessModal closeModal={closeModal} />
                )}
            </FormProvider>
        </ModalWrapper>
    )
}
