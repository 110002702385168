import * as Sentry from '@sentry/react'
import { Suspense } from 'react'
import { Toaster } from 'react-hot-toast'
import ProgressBar from './components/ProgressBar'
import AuthContextProvider from './context/auth'
import { ImpersonationContextProvider } from './context/impersonation'
import RolesContextProvider from './context/roles'
import ErrorFallBack from './layout/ErrorFallBack'
import AppRoutes from './routes/Routes'
import InvoiceItemsContextProvider from './context/invoice-items'
import CustomersContextProvider from './context/customers'

function App() {
    return (
        <Suspense fallback={<ProgressBar />}>
            <Sentry.ErrorBoundary
                fallback={() => {
                    return <ErrorFallBack />
                }}
            >
                <AuthContextProvider>
                    <RolesContextProvider>
                        <CustomersContextProvider>
                            <InvoiceItemsContextProvider>
                                <ImpersonationContextProvider>
                                    <AppRoutes />
                                    <Toaster
                                        toastOptions={{
                                            success: {
                                                style: {
                                                    background: 'green',
                                                    color: 'white',
                                                },
                                            },
                                            error: {
                                                style: {
                                                    background: 'red',
                                                    color: 'white',
                                                },
                                            },
                                            position: 'top-right',
                                            style: {
                                                minWidth: '300px',
                                            },
                                        }}
                                    />
                                </ImpersonationContextProvider>
                            </InvoiceItemsContextProvider>
                        </CustomersContextProvider>
                    </RolesContextProvider>
                </AuthContextProvider>
            </Sentry.ErrorBoundary>
        </Suspense>
    )
}

export default App
