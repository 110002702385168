import Table from '@/components/Table'
import { useCurrencyContext, useInvoiceContext } from '@/context'
import { ColumnSort } from '@/types/utils'
import { formatAmountNoSymbol } from '@/utils/money'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
// import InvoiceEmptyState from '@/assets/invoice-empty-state.svg'
import EmptyStateUserIcon from '@/assets/EmptyStateUser.svg'
import { topCustomers } from '@/services/invoice/types'
import { useSearchParams } from 'react-router-dom'

type Props = {
    customers: topCustomers[]
}
export default function TopCustomersTable({ customers }: Props) {
    const columnHelper = createColumnHelper<topCustomers>()
    const { setSort } = useInvoiceContext()
    const [searchParams] = useSearchParams()

    const { selectedCurrencyInfo } = useCurrencyContext()
    const currency = searchParams.get('currency') as string

    const handleSort = (sort: ColumnSort) => {
        setSort?.(sort)
    }
    const columns: ColumnDef<topCustomers, any>[] = [
        columnHelper.accessor('customer.name', {
            header: () => <p>Customer</p>,
            cell: cell => cell.renderValue(),
            enableSorting: true,
        }),
        columnHelper.accessor('customer.email', {
            header: () => <p>Email Address</p>,
            cell: cell => cell.renderValue(),
            enableSorting: false,
        }),
        columnHelper.accessor('customer.contact.phone', {
            header: () => <p>Phone number</p>,
            cell: cell => cell.renderValue(),
            enableSorting: false,
        }),
        columnHelper.accessor('total_payout', {
            header: () => <p>Payment made</p>,
            cell: cell => {
                return (
                    <div className=''>
                        {selectedCurrencyInfo(currency)?.symbol}
                        {formatAmountNoSymbol(Number(cell.renderValue()))}
                    </div>
                )
            },
            enableSorting: true,
        }),
    ]
    return (
        <section className=''>
            <Table
                data={customers}
                columns={columns}
                handleSort={handleSort}
                emptyStateDescription=''
                emptyStateTitle='No top paying customer yet'
                emptyStateImg={EmptyStateUserIcon}
            />
        </section>
    )
}
