import ModalWrapper from '../ModalWrapper'
import { BlueWarning } from '@/assets/assets.export'
import { Button } from '@/components/Button'
import { useNavigate } from 'react-router-dom'

type Props = {
    closeModal: () => void
    navLink: string
    desc: string
}

export const NoPolicyWarning = ({ closeModal, navLink, desc }: Props) => {
    const navigate = useNavigate()
    return (
        <ModalWrapper shouldConfirmClose closeModal={() => closeModal()}>
            <div className='w-screen lg:w-[25rem] p-6 flex items-center justify-center flex-col'>
                <div className='p-3.5 rounded-full bg-[#EBF5FF]'>
                    <BlueWarning />
                </div>
                <p className='mt-8 text-center font-medium'>{desc}</p>
                <div className='mt-16 flex gap-4 items-center justify-center'>
                    <Button
                        onClick={() => {
                            navigate(navLink)
                        }}
                        className='rounded-lg bg-black hover:bg-black'
                    >
                        Set it up now
                    </Button>
                </div>
            </div>
        </ModalWrapper>
    )
}
