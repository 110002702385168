import React, { useState } from 'react'
import ModalWrapper from '../ModalWrapper'
import { CancelIcon } from '@/assets/assets.export'
import { Controller, useForm } from 'react-hook-form'
import { useSetVaultPassword } from '@/services/vault/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { Input } from '@/components/Input'
import { EyeIcon } from 'lucide-react'
import { EyeOffIcon } from 'lucide-react'
import { Button } from '@/components/Button'
import PaymentOTP from '../PaymentOTP'
import classNames from 'classnames'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    vault_id: string | undefined
    resetPassword?: boolean
}

type FormType = {
    new_password: string
    confirm_password: string
}

export const ActivateVaultModal = ({ closeModal, vault_id, resetPassword = false }: Props) => {
    const [showPassword, setShowPassword] = useState(false)
    const [step, setStep] = useState(resetPassword ? 1 : 2)
    const handleShowPassword = () => {
        setShowPassword(prev => !prev)
    }

    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: { isValid },
    } = useForm<FormType>({})

    const newP = watch('new_password')
    const confirmP = watch('confirm_password')
    const dontMatch = confirmP?.length > 0 ? newP !== confirmP : false

    const { mutate: setPasswordFn, isLoading } = useSetVaultPassword({
        onSuccess: () => {
            toastHandler({ message: 'Password set sucessfully', state: 'success' })
            closeModal(false)
        },
    })
    const onSubmit = async (values: FormType) => {
        if (step === 1) {
            setStep(2)
            return
        }
        if (step === 2) {
            setPasswordFn({
                id: vault_id,
                password: values.new_password,
            })
            reset()
        }
    }
    return (
        <ModalWrapper variants={undefined} formId='fileForm' closeModal={() => closeModal(false)} shouldConfirmClose>
            <main className=' w-screen lg:w-[35rem] rounded-[20px]'>
                <section className='flex items-center border-b border-[#DADCE0] px-5 py-[18px] justify-between'>
                    <span className='flex-1 text-lg font-medium'>
                        {resetPassword ? 'Reset' : 'Set'} personal folder password
                    </span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <div className=''>
                    <form onSubmit={handleSubmit(onSubmit)} className=''>
                        <p className={classNames('font-medium text-base px-6', step === 2 && 'py-4')}>
                            {resetPassword
                                ? step === 2
                                    ? 'Set new password'
                                    : ''
                                : 'Kindly set a password for your personal folders to be safely kept.'}
                        </p>
                        <div>
                            {step === 1 && (
                                <PaymentOTP close={() => closeModal(false)} name='vault' nextStep={() => setStep(2)} />
                            )}
                            {step === 2 && (
                                <div className='flex flex-col gap-6 px-7'>
                                    {fields.map(item => (
                                        <fieldset className='flex flex-col flex-1 '>
                                            <label className='mb-3 text-gray7 text-sm' htmlFor={item.field_name}>
                                                {item.header}
                                            </label>
                                            <Controller
                                                name={item.field_name}
                                                rules={{
                                                    required: true,
                                                    minLength: 2,
                                                }}
                                                control={control}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        type={showPassword ? 'text' : 'password'}
                                                        onChange={e => {
                                                            field.onChange(e.target.value)
                                                        }}
                                                        placeholder={item.placeholder}
                                                        className='border-[#DADCE0] h-[2.5rem] placeholder:text-xs'
                                                        rightIcon={
                                                            showPassword ? (
                                                                <EyeIcon
                                                                    onClick={handleShowPassword}
                                                                    className='cursor-pointer'
                                                                    color='#5E5E5E'
                                                                />
                                                            ) : (
                                                                <EyeOffIcon
                                                                    onClick={handleShowPassword}
                                                                    className='cursor-pointer'
                                                                    color='#5E5E5E'
                                                                />
                                                            )
                                                        }
                                                    />
                                                )}
                                            />
                                            {dontMatch && (
                                                <p className='text-red-500 text-xs mt-0.5'>Passwords don't match</p>
                                            )}
                                        </fieldset>
                                    ))}
                                </div>
                            )}
                        </div>
                        {step === 2 && (
                            <div className='grid grid-cols-2 mt-4 gap-3 border-t border-gray8 py-3 px-5'>
                                <Button variant={'outline'} className='px-0' onClick={() => closeModal(false)}>
                                    {resetPassword ? 'back' : 'Set it later'}
                                </Button>
                                <Button
                                    disabled={!isValid || dontMatch || isLoading}
                                    loading={isLoading}
                                    variant='default'
                                    role='button'
                                    className='whitespace-nowrap'
                                >
                                    {resetPassword ? 'Reset password' : 'Set password'}
                                </Button>
                            </div>
                        )}
                    </form>
                </div>
            </main>
        </ModalWrapper>
    )
}

const fields: {
    field_name: 'new_password' | 'confirm_password'
    header: string
    placeholder: string
}[] = [
    {
        field_name: 'new_password',
        header: 'Set password',
        placeholder: 'Enter new password',
    },
    {
        field_name: 'confirm_password',
        header: 'Confirm password',
        placeholder: 'Confirm new password',
    },
]
