import { ReactNode, createContext, useMemo } from 'react'

import { useGetOrgCustomers } from '@/services/customers/queries'
import { useAuthContext } from '.'
import { Customers } from '@/types/customers'
import { customersSidebarPermissions } from '@/layout/LayoutComponents/utils'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'

export type CustomersDefaultType = {
    orgCustomers: Customers[]
}

const defaultValues: CustomersDefaultType = {
    orgCustomers: [],
}

export const CustomersContext = createContext(defaultValues)

export default function CustomersContextProvider({ children }: { children: ReactNode }) {
    const { selectedAccount } = useAuthContext()

    const hasPermission = useHasAccessPermisson(customersSidebarPermissions)

    const { data: org_customers } = useGetOrgCustomers({
        enabled: hasPermission,
        queryParams: {
            org: selectedAccount?.org?.id as string,
        },
    })

    const orgCustomers = useMemo(() => org_customers?.customers ?? [], [org_customers])

    return <CustomersContext.Provider value={{ orgCustomers }}>{children}</CustomersContext.Provider>
}
