import { Budget } from './budget'
import { EmployeeType, OrgType, UserOrgAccount } from './org'
import { TransactionType } from './transactions'
import { User } from './user'
import { Pagination } from './utils'

export enum PayrollStatus {
    UNPAID = 'unpaid',
    PROCESSING = 'processing',
    PAID = 'paid',
}

export enum EmploymentType {
    FULL_TIME = 'full time',
    PART_TIME = 'part time',
    CONTRACT = 'contract',
}

export enum PayFrequency {
    WEEKLY = 'weekly',
    BIWEEKLY = 'biweekly',
    MONTHLY = 'monthly',
}
export type defaultAdditionProps = {
    reason: string
    amount: number
    type: 'percentage' | 'amount'
    name: string
    id: string
    field_type: 'deduction' | 'bonus'
    reccuring?: boolean
}

export type defaultMilestoneProps = {
    title: string
    amount: string
    due_date: string
    id: string | Date
}

export type PaymentProfile = {
    _id: string | number | number[]
    employee: EmployeeType
    status: PayrollStatus
    employment_type: EmploymentType
    gross_salary: string
    net_salary: string | any
    bonuses: defaultAdditionProps[]
    deductions: defaultAdditionProps[]
    other_deductions: string
    pay_frequency: PayFrequency
    pay_date: string
    pay_day: string | number
    createdAt: string
    updatedAt: string
    days_worked?: string
    eligible: boolean
    id: string
    start_date: string | number | Date
    prorate: boolean
    current_payout: string
    payrolls: any
    computed_bonus: number
    computed_deduction: number
    account: User
    active: boolean
    currency: string
    current_period_end: Date
    current_period_start: Date
}

export enum LeaveStatus {
    PENDING = 'pending',
    APPROVED = 'approved',
    DECLINED = 'declined',
    CANCELLED = 'cancelled',
    PARTIALLY_APPROVED = 'partially_approved',
}

export enum LeaveType {
    SICK_DAYS = 'sick_days',
    VACATION = 'vacation',
    CONFERENCE = 'conference',
    PARENTAL = 'parental',
    MISCELLANEOUS = 'miscellaneous',
}

export type UserTypeLeave = {
    account: EmployeeType
    end_date: Date[]
    id: string
    _id: string
    is_paid_leave: boolean
    leave_type: LeaveType
    org: string
    reason: string
    start_date: Date[]
    approvers: {
        account: UserOrgAccount
        approved: boolean
        id: string
        _id: string
    }[]
    status: LeaveStatus
    createdAt: string
}

export interface createLeaveProps {
    start_date: Date
    end_date: Date
    reason?: string
    account: string
    leave_type: string
    is_paid_leave?: boolean
    org: string
}

export type OrgPayrollHistoryType = {
    _id: string
    total_payout: string
    employees_paid: number
    payroll_items: PaymentProfile[]
    pay_date: string
    current_period: {
        start: string
        end: string
    }
    budget: Budget | null
    transactions: TransactionType[]
    total_salaries: number
}

export type ContractHistory = {
    contract: ContractPayrollType
    createdAt: string
    id: string
    milestone: MilestoneType
    org: UserOrgAccount
    owner: EmployeeType
    transaction: string
    updatedAt: string
}

export type OrgContractPayHistory = {
    history: ContractHistory[]
    pagination: Pagination
}

export type LeaveSettingsType = {
    sick_days: number
    vacation: number
    conference: number
    parental: number
    miscellaneous: number
    id: string
}

export type ContractType = {
    title: string
    name: string
    duration: string
    total_pay: string
    start_date: string
    end_date: string
}

export type MilestoneType = {
    title: string
    due_date: string
    amount: string
    progress_status?: 'completed' | 'pending' | 'in-progress' | 'in progress'
    payment_status?: 'paid' | 'unpaid' | 'failed' | 'processing'
    _id?: string
    id?: string
    uniqueId?: string
}

export type ContractPayrollType = {
    org: OrgType
    employee: EmployeeType
    employment_type: string
    total_pay: string
    pay_frequency: string
    currency: string
    start_date: string
    end_date: string
    contract_title: string
    milestones: MilestoneType[]
    completed_milestones: MilestoneType[]
    createdAt: string
    updatedAt: string
    id: string
    bank: boolean
    status: string
    reason?: string
    attact_budget?: string
    budget: Budget
}

export type PaymentProfileTypes = {
    active: boolean
    employee: EmployeeType
    employment_type: string
    gross_salary: string
    net_salary: string
    bonuses: defaultAdditionProps[]
    deductions: defaultAdditionProps[]
    pay_frequency: string
    currency: string
    start_date: string
    prorate: false
    amount_to_prorate: string
    last_payday: string
    next_payday: string
    total_pay: string
    status: string
    end_date: string
    contract_title: string
    milestones: MilestoneType[]
    completed_milestones: MilestoneType[]
}

export type BulkProfileCSVType = {
    'Email Address': string
    'Employment Type': string
    'Full Name': string
    'Gross Salary': number
    'Org Deductions': string
    'Org Bonuses': string
    'Start Date': string
    'Pay Frequency': string
    bonuses: (defaultAdditionProps & { toggle?: boolean })[]
    deductions: (defaultAdditionProps & { toggle?: boolean })[]
}
