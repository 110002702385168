import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { useToggleLinkBudget } from '@/services/budget/mutations'
import { useGetOrgBudget } from '@/services/budget/queries'
import { QueryOrgBudgetType } from '@/services/budget/types'
import { Budget } from '@/types/budget'
import { ExpenseType } from '@/types/expense'
import { formatAmount, formatAmountNoSymbol } from '@/utils/money'
import { AlertCircleIcon } from 'lucide-react'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ModalWrapper from '../../ModalWrapper'
import RequestBudgetIncrease from '../../Payroll/PayrollProcess/RequestBudgetIncrease'

interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    selectedExpense?: ExpenseType
}

export default function LinkExpenseToBudget({ setShowModal, selectedExpense }: Props) {
    const { selectedAccount } = useAuthContext()
    const [selectedBudget, setSelectedBudget] = useState<Budget>()
    const [steps, setSteps] = useState(1)

    const { data: org_budget } = useGetOrgBudget({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            // account: submitterId as string,
            currency: selectedExpense?.currency.id as string,
        },
    })
    const orgBudget = useMemo(() => org_budget ?? [], [org_budget])
    const {
        handleSubmit,
        control,
        watch,
        formState: { isValid },
        getValues,
        resetField,
    } = useForm<Record<'attach_budget' | 'budget', string>>({
        defaultValues: {},
    })
    const { mutate: toggleBudgetLink, isLoading: isUpdateLoading } = useToggleLinkBudget({
        queryParams: { id: selectedExpense?.id as string },
        onSuccess: () => {
            setShowModal(false)
        },
    })

    const handleUpdateExpenseWithBudget = (values: any) => {
        const isAttached = values?.attach_budget === 'yes'

        const payload = {
            budget_id: isAttached ? values?.budget : undefined,
            item_id: selectedExpense?.id,
            item_type: 'expense',
        }

        toggleBudgetLink(payload, {
            onSuccess: () => {
                toastHandler({
                    message: isAttached ? 'Expense linked to budget successfully' : 'Expense unlinked from budget',
                    state: 'success',
                })
            },
        })
    }
    const watchLinkBudget = watch('attach_budget')
    const budgetId = watch('budget')
    // const allowedUsersArr = orgBudget[0].budgets.filter(budget => budget.accounts.map(account => account.id ))

    useEffect(() => {
        if (budgetId && watchLinkBudget === 'yes') {
            setSelectedBudget((orgBudget as QueryOrgBudgetType)?.budgets?.find(budget => budget?._id === budgetId))
            return
        }
        setSelectedBudget(undefined)
        resetField('budget', { defaultValue: '' })
    }, [budgetId, orgBudget, setSelectedBudget, watchLinkBudget, resetField])

    return (
        <ModalWrapper>
            {steps === 1 && (
                <section className='w-screen lg:w-[35rem] min-h-[35vh] max-h-[32rem] overflow-scroll relative'>
                    <h2 className='font-semibold text-[#31254B] px-6 mt-8'>
                        Do you want to link this expense to a budget?
                    </h2>
                    {/* <div
                        className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                        onClick={() => setShowModal(false)}
                    >
                        <AiOutlineClose fontSize={25} />
                    </div> */}

                    <form className='px-6 mt-4' onSubmit={handleSubmit(handleUpdateExpenseWithBudget)}>
                        <div className='pb-10'>
                            <div className={`flex flex-col flex-1 gap-3 `}>
                                <label
                                    htmlFor='firstName'
                                    className='text-[#202020] text-[15px] flex items-center gap-1 font-medium'
                                >
                                    Linking your expense to a budget can help to further monitor and control spending
                                    for your company
                                </label>
                                <Controller
                                    name={'attach_budget'}
                                    rules={{
                                        minLength: 2,
                                        required: true,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup
                                            {...field}
                                            value={field.value as string}
                                            className='flex flex-col gap-2 mt-4 disabled:opacity-50 text-[#5e5e5e] font-medium '
                                            onValueChange={value => {
                                                field.onChange(value)
                                            }}
                                        >
                                            <label htmlFor={'yes'} className={`flex gap-2 items-center   `}>
                                                <RadioGroupItem type='button' value={'yes'} id={'yes'} />
                                                Yes, link to a budget
                                            </label>
                                            <label htmlFor={'no'} className={`flex gap-2 items-center   `}>
                                                <RadioGroupItem type='button' value={'no'} id={'no'} />
                                                No, don't link to a budget
                                            </label>
                                        </RadioGroup>
                                    )}
                                />
                            </div>
                            {watchLinkBudget === 'yes' && (
                                <div className={`flex flex-col flex-1 gap-3 mt-6`}>
                                    <label
                                        htmlFor='firstName'
                                        className='text-[#202020] text-[15px] font-medium flex items-center gap-1'
                                    >
                                        Select Budget
                                    </label>
                                    <Controller
                                        name={'budget'}
                                        rules={{
                                            minLength: 2,
                                            required: true,
                                        }}
                                        control={control}
                                        render={({ field }) => (
                                            <Select {...field} onValueChange={field.onChange} value={field.value}>
                                                {(orgBudget as QueryOrgBudgetType)?.budgets?.length === 0 ? (
                                                    <span className='bg-[#AE6B57]/20 text-[#AE6B57] p-2 rounded-md '>
                                                        No budget affiliated with the user
                                                    </span>
                                                ) : (
                                                    <SelectTrigger className='h-[3rem] border-[#DADCE0] text-black text-[13px]'>
                                                        {getValues()?.budget ? (
                                                            <SelectValue />
                                                        ) : (
                                                            <span className='text-[#5e5e5e] font-medium'>
                                                                Choose a budget to link
                                                            </span>
                                                        )}
                                                    </SelectTrigger>
                                                )}
                                                <SelectContent className='max-h-[200px]  lg:min-w-[32rem] overflow-scroll'>
                                                    {watchLinkBudget === 'yes' &&
                                                        (orgBudget as QueryOrgBudgetType)?.budgets?.map(budget => {
                                                            const allowedBudget = budget.accounts.filter(
                                                                account =>
                                                                    account.account === selectedExpense?.submitter.id ||
                                                                    account._id === selectedExpense?.submitter.id
                                                            )
                                                            // const adminPermitted = budget.manager.find(
                                                            //     mang => mang?.user?._id === selectedAccount?.user?.id
                                                            // )
                                                            return (
                                                                <div className='flex items-center justify-between'>
                                                                    <SelectItem
                                                                        value={budget?._id ?? budget?.id}
                                                                        key={budget?._id ?? budget?.id}
                                                                        disabled={
                                                                            allowedBudget?.length == 0 &&
                                                                            budget.accounts.length !== 0
                                                                            //  || !adminPermitted
                                                                        }
                                                                        className='w-full'
                                                                    >
                                                                        {budget.name} (Balance:{' '}
                                                                        {selectedExpense?.currency.symbol}
                                                                        {formatAmountNoSymbol(budget?.available_amount)}
                                                                        )
                                                                    </SelectItem>
                                                                    {allowedBudget?.length == 0 &&
                                                                        budget.accounts?.length !== 0 && (
                                                                            //  || !adminPermitted
                                                                            <div className=''>
                                                                                <TooltipProvider delayDuration={10}>
                                                                                    <Tooltip>
                                                                                        <TooltipTrigger className='mr-2'>
                                                                                            <AlertCircleIcon
                                                                                                size={15}
                                                                                            />
                                                                                        </TooltipTrigger>

                                                                                        <TooltipContent>
                                                                                            <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                                                                {/* {true ? "This user is not part of the users of this budget" : `You're not a manager of this budget`}     */}
                                                                                                This user is not part of
                                                                                                the users of this budget
                                                                                            </p>
                                                                                        </TooltipContent>
                                                                                    </Tooltip>
                                                                                </TooltipProvider>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            )
                                                        })}
                                                </SelectContent>
                                            </Select>
                                        )}
                                    />
                                </div>
                            )}
                            {Number(selectedExpense?.amount) > Number(selectedBudget?.available_amount) && (
                                <div className='bg-[#F8F1DE] rounded-lg mt-8 text-sm font-medium p-4 px-5'>
                                    The selected budget balance ({formatAmount(selectedBudget?.available_amount)}) is
                                    less than the total payout amount ({formatAmount(selectedExpense?.amount)}). You
                                    need a balance of{' '}
                                    <span className='font-semibold'>
                                        {formatAmount(
                                            Number(selectedExpense?.amount) - Number(selectedBudget?.available_amount)
                                        )}
                                    </span>{' '}
                                    to process this payroll
                                    <button
                                        onClick={() => {
                                            setSteps(2)
                                        }}
                                        className='block mt-6 text-[#454ADE] underline'
                                    >
                                        Increase budget
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className='flex items-center justify-end gap-4 px-6  py-2 sticky left-0 right-0 bottom-0 bg-white rounded-b-lg'>
                            <Button
                                onClick={() => setShowModal(false)}
                                className='border-none min-w-[120px] bg-transparent text-[black] hover:bg-transparent'
                            >
                                Cancel
                            </Button>
                            <Button
                                type={'submit'}
                                className='bg-[#454ADE] text-white px-8 h-[3.5rem] rounded-lg text-[14px] min-w-[130px]'
                                disabled={
                                    !isValid ||
                                    Number(selectedExpense?.amount) > Number(selectedBudget?.available_amount) ||
                                    watchLinkBudget === 'no'
                                }
                                loading={isUpdateLoading}
                            >
                                Attach Budget
                            </Button>
                        </div>
                    </form>
                </section>
            )}
            {steps === 2 && (
                <RequestBudgetIncrease
                    closeModal={setShowModal}
                    selectedBudget={selectedBudget}
                    setSelectedBudget={setSelectedBudget}
                    setSteps={setSteps}
                />
            )}
        </ModalWrapper>
    )
}
