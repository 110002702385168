import { MoveRight, ProfileEditIcon } from '@/assets/assets.export'
import { ReactComponent as AccountsIcon } from '@/assets/accounts-icon.svg'
import CreateAccountModal from '@/components/Modals/Accounts/CreateAccountsModal'
import FundAccountModal from '@/components/Modals/Accounts/FundAccountModal'
import TransferModal from '@/components/Modals/Accounts/TransferModalNew'
import ProgressBar from '@/components/ProgressBar'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'

import PageContent from '@/layout/PageContent'
import { useRefreshBankBalance } from '@/services/accounts/mutations'
import { useGetDedicatedBankAccount } from '@/services/accounts/queries'
import { DedicatedBankAccountType } from '@/types/accounts'
import { formatAmount } from '@/utils/money'
import { handleCopyClick, isStagingEnvironment } from '@/utils/utils'
import { AlertCircleIcon, ClipboardList, Copy, LucideRefreshCcw, Plus, Send } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import TransactionHistory from '../components/TransactionsHistoryTable'
import AccountTransactionsChart from '../components/AccountTransactionsChart'
import { Tabs, TabsList, TabsTrigger, TabTriggersContainer } from '@/components/Tabs/Tabs'
import { TabsContent } from '@radix-ui/react-tabs'
import { GenerateAccountStatement } from '@/components/Modals/Accounts/GenerateAccountStatement'

const viewTypeConst = {
    history: 'history',
    analytics: 'analytics',
}
export default function AccountDetailsPage() {
    const { accountId } = useParams()
    const navigate = useNavigate()
    const [openFundModal, setOpenFundModal] = useState(false)
    const [openTransferModal, setOpenTransferModal] = useState(false)
    const [openEditAccountModal, setOpenEditAccountModal] = useState(false)
    const [openGenerateStatement, setOpenGenerateStatement] = useState(false)

    const [searchParams] = useSearchParams()
    const viewType = searchParams.get('view') ?? viewTypeConst.history

    const { data, isLoading, isRefetching } = useGetDedicatedBankAccount({
        queryParams: {
            id: accountId as string,
        },
    })

    const { mutate: refreshBalance, isLoading: refreshing } = useRefreshBankBalance({
        queryParams: {
            id: accountId as string,
        },
    })

    const bankAccount = useMemo(() => data ?? ({} as DedicatedBankAccountType), [data])
    const isBizpendAccount = bankAccount?.provider === 'anchor'
    console.log(bankAccount)

    const accountDetails = [
        {
            name: 'Account number',
            desc: bankAccount?.account_number,
        },
        {
            name: 'Account name',
            desc: bankAccount?.account_name,
        },
        {
            name: 'Bank name',
            desc: bankAccount?.bank_name,
        },
    ]

    if (isLoading) return <ProgressBar />
    return (
        <PageContent>
            <div className='text-gray7 flex items-center gap-2 mt-4 lg:mt-8 mx-2 md:mx-0'>
                <MoveRight
                    className='text-gray7 rotate-180 cursor-pointer'
                    onClick={() =>
                        navigate(
                            `/dashboard/accounts/${bankAccount?.connection_type === 'create' ? 'bizpend_accounts' : 'linked_accounts'}`
                        )
                    }
                />{' '}
                <span>back</span>
            </div>
            <div className='mt-1 lg:mt-8  flex justify-end'>
                <button
                    onClick={() => refreshBalance({ id: accountId })}
                    disabled={refreshing}
                    className='disabled:opacity-70 flex items-center gap-1 font-medium text-sm text-brightGreen'
                >
                    <LucideRefreshCcw
                        strokeWidth={2.3}
                        size={10}
                        className={`text-brightGreen ${refreshing ? 'animate-spin' : ''}`}
                    />
                    Refresh balance{' '}
                </button>
            </div>
            <section className={`grid grid-cols-5 mt-2 gap-10 ${isRefetching ? 'opacity-50' : ''}`}>
                <div className='col-span-5 md:col-span-3 bg-white rounded-xl p-6 mx-2 md:mx-0'>
                    <div className='flex items-center justify-between border-b border-gray5 pb-2'>
                        <div className='flex items-center gap-5'>
                            <AccountsIcon />
                            <h3 className='font-medium text-lg opacity-80 capitalize'>
                                {bankAccount?.nickname?.toLowerCase() ?? bankAccount?.account_name?.toLowerCase()}
                            </h3>
                        </div>

                        {isBizpendAccount && (
                            <ProfileEditIcon
                                className='w-5 h-5 cursor-pointer'
                                onClick={() => setOpenEditAccountModal(true)}
                            />
                        )}
                    </div>
                    <div className='mt-5'>
                        <p className='flex items-center gap-1 text-gray7'>
                            Account balance
                            <TooltipProvider delayDuration={10}>
                                <Tooltip>
                                    <TooltipTrigger className='mr-2' type='button'>
                                        <AlertCircleIcon size={15} />
                                    </TooltipTrigger>

                                    <TooltipContent>
                                        <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                            This is your available balance
                                        </p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </p>
                        <h3 className='text-3xl text-gray7 font-bold border-b border-gray5 mt-1 pb-4'>
                            {formatAmount(bankAccount?.balance)}
                        </h3>
                        <div className='flex flex-col lg:flex-row gap-2 lg:gap-6 mt-8'>
                            <button
                                aria-label='Bank details'
                                className='hover:scale-95 duration-300 transition-all flex items-center gap-2 border border-gray2/60 bg-gray2/10 p-4 px-5.5 rounded-md text-base font-medium text-gray7 w-full'
                                onClick={() => setOpenFundModal(true)}
                            >
                                <Plus />
                                Fund Account
                            </button>
                            <button
                                aria-label='Bank details'
                                className='hover:scale-95 duration-300 transition-all flex items-center gap-2 border border-gray2/60 bg-gray2/10 p-4 px-5.5 rounded-md text-base font-medium text-gray7 w-full'
                                onClick={() => setOpenTransferModal(true)}
                            >
                                <Send />
                                Make Transfer
                            </button>
                            <button
                                aria-label='Bank details'
                                className='hover:scale-95 duration-300 transition-all flex items-center gap-2 border border-gray2/60 bg-gray2/10 p-4 px-5.5 rounded-md text-base font-medium text-gray7 w-full'
                                onClick={() => setOpenGenerateStatement(true)}
                            >
                                <ClipboardList />
                                Statement
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-span-5 md:col-span-2  rounded-xl p-6 bg-white mx-2 md:mx-0 h-fit'>
                    <div className='flex items-center justify-between border-b border-gray5 pb-2'>
                        <h2 className='font-medium opacity-80'>Account details</h2>
                    </div>
                    <ul className='mt-4 space-y-2 '>
                        {accountDetails?.map(detail => (
                            <li className='text-sm flex items-center justify-between'>
                                <div>
                                    <h3 className='text-gray7 '>{detail?.name}</h3>
                                    <p className='font-medium'>{detail?.desc}</p>
                                </div>
                                <button
                                    className='text-gray7 text-xs flex items-center font-semibold gap-1'
                                    onClick={() => handleCopyClick(detail?.desc)}
                                >
                                    <span className=''>Copy</span>
                                    <Copy size={12} className='text-gray7' />
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
            <Tabs value={viewType}>
                <TabsList className='border-none pt-0 h-full flex '>
                    <TabTriggersContainer className='border-none  gap-4 w-full mt-10 ml-5 lg:ml-0'>
                        <TabsTrigger
                            onClick={() => navigate(`?view=${viewTypeConst.history}`)}
                            value={viewTypeConst.history}
                            className='border-none group data-[state=active]:bg-white data-[state=active]:text-black data-[state=active]:shadow-md  h-fit py-2 px-3 rounded-md'
                        >
                            Transaction history
                        </TabsTrigger>
                        {isStagingEnvironment() && (
                            <TabsTrigger
                                onClick={() => navigate(`?view=${viewTypeConst.analytics}`)}
                                value={viewTypeConst.analytics}
                                className='border-none group data-[state=active]:bg-white data-[state=active]:text-black data-[state=active]:shadow-md  h-fit py-2 px-3 rounded-md'
                            >
                                Overview
                            </TabsTrigger>
                        )}
                    </TabTriggersContainer>
                </TabsList>
                <TabsContent value={viewTypeConst.history}>
                    <section className='mt-8'>
                        <TransactionHistory />
                    </section>
                </TabsContent>
                {isStagingEnvironment() && (
                    <TabsContent value={viewTypeConst.analytics}>
                        <section className='mt-8'>
                            <AccountTransactionsChart />
                        </section>
                    </TabsContent>
                )}
            </Tabs>

            {openFundModal && (
                <FundAccountModal
                    accountDetails={accountDetails}
                    account={bankAccount}
                    closeModal={() => setOpenFundModal(false)}
                />
            )}
            {openTransferModal && (
                <TransferModal account={bankAccount} closeModal={() => setOpenTransferModal(false)} />
            )}
            {openEditAccountModal && (
                <CreateAccountModal
                    edit={true}
                    closeModal={() => setOpenEditAccountModal(false)}
                    nameOfAccount={bankAccount?.nickname}
                />
            )}
            {openGenerateStatement && (
                <GenerateAccountStatement account={bankAccount} closeModal={() => setOpenGenerateStatement(false)} />
            )}
        </PageContent>
    )
}
