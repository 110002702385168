import upgradeheader from '@/assets/upgrade-header.png'
import { Button } from '@/components/Button'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import { useAuthContext } from '@/context'
import { useGetModuleAvailable, useGetOrgPlans } from '@/services/billing/queries'
import { formatAmount } from '@/utils/money'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AiOutlineClose } from 'react-icons/ai'
import Lottie from 'react-lottie'
import ModalWrapper from '../ModalWrapper'
import { Billing, ModuleNameEnum } from '@/types/billing'
import PlansCard from '@/modules/dashboard/Settings/partials/PlansAndPricing/components/PlansCard'
import { defaultOptions, storagePlan } from './utils'
import { PayForStorage } from './PayForStorage'

type Props = {
    closeModal: () => void
    initialStep?: number
    planId?: string
    module?: ModuleNameEnum
}

export default function UpgradeVaultPlan({ closeModal, initialStep, planId: selectedPlanId }: Props) {
    const [step, setStep] = useState(initialStep ?? 1)
    const [showPayForStorage, setShowPayForStorage] = useState(false)
    const { orgPlans: plansData, selectedAccount, currentModule } = useAuthContext()
    const { data } = useGetOrgPlans()
    const orgPlans = useMemo(() => plansData ?? data ?? [], [data])

    const orgBusinessPlan = selectedAccount?.org?.business_plan

    const { isLoading: isChecking } = useGetModuleAvailable({
        enabled: !!currentModule,
        queryParams: {
            module: currentModule as string,
        },
    })

    const {
        handleSubmit,
        control,
        watch,
        formState: { isValid },
    } = useForm<{ plan_id: string; storage_plan: string | undefined }>({
        defaultValues: {
            plan_id:
                typeof orgBusinessPlan?.plan === 'string'
                    ? (orgBusinessPlan?.plan as string)
                    : (orgBusinessPlan?.plan?.id as string),
            storage_plan: undefined,
        },
    })

    const planId = watch('plan_id')
    const storagePlanId = watch('storage_plan')

    const OnSubmit = () => {
        setShowPayForStorage(true)
    }

    return (
        <ModalWrapper closeModal={() => closeModal()}>
            {step === 2 && (
                <UpgradeSuccess
                    amount={
                        !storagePlanId
                            ? (orgPlans?.find(orgPlan => orgPlan?.id === (selectedPlanId ?? planId))?.price as string)
                            : storagePlan?.[Number(storagePlanId) - 1].price
                    }
                    closeModal={() => closeModal()}
                />
            )}
            {step === 1 && (
                <section className='w-screen lg:w-[53rem] min-h-[69vh] max-h-[90vh] overflow-scroll relative bg-[#F9F9F9] rounded-lg'>
                    <form onSubmit={handleSubmit(OnSubmit)}>
                        <div className='relative top-0  rounded-t-lg z-50 overflow-hidden'>
                            <div className='z-0 h-16 lg:h-24'>
                                <img src={upgradeheader} alt='SVg image' />
                            </div>
                            <div className=' px-3 flex justify-between text-white items-center z-10 absolute top-[35%] lg:top-1/2 -translate-y-1/2 w-full'>
                                <div>
                                    <h2 className='font-semibold text-xl'>Upgrade Your Plan for More Storage</h2>
                                    <p className='text-xs mt-1'>
                                        Upgrade your subscription plan to enhance your experience
                                    </p>
                                </div>
                                <div
                                    className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                                    onClick={() => closeModal()}
                                >
                                    <AiOutlineClose fontSize={25} />
                                </div>
                            </div>
                        </div>
                        <section className={`mt-8 mx-6 mb-24 lg:mb-20 ${isChecking ? 'opacity-90' : ''}`}>
                            <div className='hidden'>
                                <Controller
                                    name='storage_plan'
                                    rules={{
                                        required: true,
                                        minLength: 1,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup
                                            {...field}
                                            onValueChange={field.onChange}
                                            className='grid grid-cols-1 lg:grid-cols-3 gap-6'
                                        >
                                            {storagePlan.map(plan => (
                                                <label
                                                    htmlFor={plan?.id}
                                                    key={plan?.id}
                                                    className={`shadow-sm rounded-lg p-2  ${field?.value === plan?.id ? 'border-brand' : 'border-gray/10'}   border cursor-pointer `}
                                                >
                                                    <div className='flex justify-between items-center gap-4'>
                                                        <div>
                                                            <h2 className='font-semibold'>{plan?.name}</h2>
                                                            <p className='text-gray7 text-xs truncate w-48'>
                                                                {' '}
                                                                {plan?.description}
                                                            </p>
                                                        </div>
                                                        <RadioGroupItem value={plan?.id} id={plan?.id} />
                                                    </div>
                                                    <div className='text-xl font-semibold my-3'>
                                                        {formatAmount(plan?.price)}
                                                        <span className='font-normal text-xs'>/ month</span>
                                                    </div>
                                                </label>
                                            ))}
                                        </RadioGroup>
                                    )}
                                />
                            </div>

                            <h3 className='text-gray7 font-medium  text-center mt-6 border-y py-2.5'>
                                Upgrade pricing plan
                            </h3>
                            <ul className='grid grid-cols-1 lg:grid-cols-3 gap-3 mt-5'>
                                {orgPlans
                                    ?.filter(plan => !plan?.archived)
                                    ?.sort((plan_1, plan_2) =>
                                        orgBusinessPlan?.plan === plan_1?.id
                                            ? -1
                                            : plan_2?.id === orgBusinessPlan?.plan
                                              ? 1
                                              : 0
                                    )
                                    .map(plan => (
                                        <PlansCard
                                            small
                                            plan_id={plan?.id}
                                            amount={formatAmount(plan?.price)}
                                            desc={plan?.description}
                                            title={plan?.name}
                                            active={(orgBusinessPlan?.plan as Billing)?.id === plan?.id}
                                            plans={plan?.metadata}
                                            btn_title={
                                                orgBusinessPlan?.plan === plan?.id ? 'Current Plan' : 'Upgrade Plan'
                                            }
                                        />
                                    ))}
                            </ul>
                        </section>

                        <div className='bg-white hidden fixed bottom-0 border-t border-gray8 w-full items-center justify-end gap-4  px-6 py-2 rounded-b-md'>
                            <Button
                                type={'submit'}
                                size={'lg'}
                                className='bg-[#454ADE] text-white px-8 h-[3rem] rounded-lg text-sm min-w-[130px]'
                                disabled={!isValid}
                            >
                                Get more storage
                            </Button>
                        </div>
                    </form>
                </section>
            )}
            {showPayForStorage && (
                <PayForStorage setStep={setStep} storagePlanId={storagePlanId} closeModal={setShowPayForStorage} />
            )}
        </ModalWrapper>
    )
}

const UpgradeSuccess = ({ closeModal, amount }: { closeModal: () => void; amount: string }) => {
    return (
        <section className='w-screen lg:w-[30rem] min-h-[55vh] max-h-[90vh] overflow-scroll relative bg-[#F9F9F9] rounded-lg'>
            <div className='flex justify-end m-3'>
                <span
                    className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                    onClick={() => closeModal()}
                >
                    <AiOutlineClose fontSize={25} />
                </span>
            </div>
            <div>
                <Lottie options={defaultOptions} height={300} width={300} />
                <h2 className='text-2xl font-semibold text-center'>Congratulations</h2>
                <p className='text-gray7 text-sm text-center mt-2'> Your plan has been upgraded successfully.</p>
                <div className='bg-[#D5D5F5] rounded-xl p-4 m-4'>
                    <h4 className='text-lg font-semibold'>Note</h4>
                    <p className='text-gray7 text-sm'>
                        The upgrade cost of {formatAmount(amount)} will be deducted from your wallet immediately. This
                        amount covers the cost for the remaining days in your current billing cycle.
                    </p>
                </div>
            </div>
        </section>
    )
}
