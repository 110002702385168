import { PayrollListType, PayrollSingleList } from '@/services/payroll/types'

import React, { useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import PayrollProcess1 from './PayrollProcess1'
import PayrollProcess2 from './PayrollProcess2'

type ConfirmationProps = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    payrollDetails: PayrollListType | PayrollSingleList
    single: boolean
}

export const PayrollProcessRoot = ({ closeModal, payrollDetails, single }: ConfirmationProps) => {
    const [steps, setSteps] = useState(1)

    const [formState, setFormState] = useState<{
        account: string
        budgetId: string
    }>({ account: '', budgetId: '' })

    return (
        <ModalWrapper>
            <div>
                {steps === 1 && (
                    <PayrollProcess1
                        setFormState={setFormState}
                        formState={formState}
                        setSteps={setSteps}
                        closeModal={closeModal}
                        totalPayrollAmount={single ? payrollDetails?.net_salary : payrollDetails?.summary?.total_payout}
                    />
                )}

                {steps === 2 && (
                    <PayrollProcess2
                        setSteps={setSteps}
                        closeModal={closeModal}
                        formState={formState}
                        payrollDetails={payrollDetails as PayrollListType}
                        single={single}
                    />
                )}
            </div>
        </ModalWrapper>
    )
}
